const en = {
    error: "Error",
    success: "Success",
    employed: "Employed",
    nonEmployed: "Non-Employed",
    retiree: "Retiree",
    loading: "Loading",
    dashboard: "Dashboard",
    // memberList: "Member List",
    // memberPolicy: "Member Policy",
    memberList: "Customer List",
    memberPolicy: "Customer Policy",
    noResultFound: "No Result Found",
    goToPage: "Go To Page",
    page: "Page",
    show: "Show",
    searchHere: "Search here",
    searchTable: "Search Table",
    exportExcel: "Export Excel",
    index: "No.",
    name: "Name",
    fullName: "Full Name",
    age: "Age",
    identityCard: "Identity Card",
    birthDate: "Birth Date",
    phoneCode: "Phone Code",
    annualIncome: "Annual Income",
    monthlyCommitment: "Monthly Commitment",
    retireeIncomeMonthly: "Retiree Monthly Income",
    phone: "Phone",
    email: "Email",
    address:    "Address",
    gender: "Gender",
    action: "Action",
    agent: "Agent",
    addAgent: "Add Agent",
    relationship: "Relationship",
    male: "Male",
    female: "Female",
    status: "Status",
    policyName: "Policy Name",
    policyNumber: "Policy Number",
    totalSumAssured: "Total Sum Assured",
    premiumAssured: "Premium Assured",
    premiumAmount: "Premium Amount",
    commencementDate: "Commencement Date",
    premiumPaymentTerm: "Premium Payment Term",
    policyTerms: "Policy Terms",
    expireDate: "Expire / Maturity Date",
    assuredName: "Assured Name",
    remark: "Remark",

    // nav
    member: "Customer",
    // member: "Member",
    list: "List",
    policy: "Policy",
    settings: "Settings",
    masterPolicy: "Master Policy",
    policyCategory: "Policy Category",
    rider: "Rider",
    questionnaire: "Questionnaire",
    // end nav

    // button
    create: "Create",
    edit: "Edit",
    save: "Save",
    delete: "Delete",
    back: "Back",
    // end button

    masterPolicySettings: "Master Policy Settings",
    policyCategorySettings: "Policy Category Settings",
    term: "Term",
    code: "Code",
    displayType: "Display Type",
    level: "Level",
    detailsMasterPolicy: "Master Policy Details",
    createMasterPolicy: "Create Master Policy",
    editMasterPolicy: "Edit Master Policy",
    createPolicyCategory: "Create Policy Category",
    detailsPolicyCategory: "Policy Category Details",
    editPolicyCategory: "Edit Policy Category",
    masterPolicyForm: "Master Policy Form",
    policyCategoryForm: "Policy Category Form",
    // detailsMemberPolicy: "Member Policy Details",
    // createMemberPolicy: "Create Member Policy",
    // editMemberPolicy: "Edit Member Policy",
    detailsMemberPolicy: "Customer Policy Details",
    createMemberPolicy: "Create Customer Policy",
    editMemberPolicy: "Edit Customer Policy",
    policyCategoryName: "Policy Category Name",
    type: "Type",
    description: "Description",
    icon: "Icon",
    currentIcon: "Current Icon",
    color: "Color",
    secondaryColor: "Secondary Color",
    pin: "Pin",
    confirmPin: "Confirm Pin",
    createAgent: "Create Agent",
    editAgent: "Edit Agent",
    detailsAgent: "Details Agent",
    // editMember: "Edit Member",
    // createMember: "Add New Member",
    // viewMember: "View Member",
    // memberPolicyForm: "Member Policy Form",
    editMember: "Edit Customer",
    createMember: "Add New Customer",
    viewMember: "View Customer",
    memberPolicyForm: "Customer Policy Form",
    profile: "Profile",
    currentProfile: "Current Profile",
    // assignedMemberList: "Assigned Member List",
    // noMemberAssignedToThisAgent: "No member assigned to this agent.",
    assignedMemberList: "Assigned Customer List",
    noMemberAssignedToThisAgent: "No Customer assigned to this agent.",
    nominationList: "Nomination List",
    allocationPercentage: "Allocation Percentage",
    contactNumber: "Contact Number",
    noNominationAssigned: "No Nomination Assigned.",
    parent: "Parent",
    husbandWife: "Husband/Wife",
    siblings: "Siblings",
    children: "Children",
    guardian: "Guardian",
    friends: "Friends",
    relative: "Relative",
    father: "Father",
    mother: "Mother",
    other: "Other",
    createRider: "Create Rider",
    assignedPolicyCategory: "Assigned Policy Category",
    detailsRider: "Rider Details",
    editRider: "Edit Rider",
    policyRider: "Policy Rider",
    noPolicyFoundOnThisUser: "No Policy Found In This User",
    noPolicyRiderFoundOnThisUser: "No Policy Rider Found In This User",
    policyList: "Policy List",
    riderList: "Rider List",
    monthly: "MONTHLY",
    quarterly: "QUARTERLY",
    halfYearly: "HALF-YEARLY",
    yearly: "YEARLY",
    contactNo: "Contact No",

    //dashboard
    totalCustomer: "Total Customer",

    placeholderEnterHere: "Enter here",
    placeholderChoose: "Choose one option",
    ring: "Ring",
    barChart: "Bar Chart",
    horizontalBarChart: "Horizontal Bar Chart",
    company: "Company",
    companyPolicy: "Company Policy",
    companyPolicyList: "Company Policy List",
    companySettings: "Company Settings",
    companyPolicySettings: "Company Policy Settings",
    companyPolicyIcon: "Company Policy Icon",
    companyName: "Company Name",
    companyCode: "Company Code",
    companyLogo: "Company Logo",
    currentCompanLogo: "Current Company Logo",
    createCompany: "Create Company",
    createCompanyPolicy: "Create Company Policy",
    detailsCompany: "Company Details",
    detailsCompanyPolicy: "Company Policy Details",
    editCompany: "Edit Company",
    editCompanyPolicy: "Edit Company Policy",
    riderGroupName: "Rider Group Name",
    riderGroupIcon: "Rider Group Icon",
    currentRiderGroupIcon: "Current Rider Group Icon",
    addNewCompanyPolicy: "Add New Company Policy",
    totalSteps: "Total Steps",
    totalQuestions: "Total Questions",
    questionnaireList: "Questionnaire List",
    questionnaireForm: "Questionnaire Form",
    questionTitle: "Question Title",
    questionDescription: "Question Description",
    questionType: "Question Type",
    questionOptions: "Question Options",
    chooseOne: "Choose One",
    chooseMultiple: "Choose Multiple",
    fillIn: "Fill In",
    addNewQuestion: "Add New Question",
    addNewAnswer: "Add New Answer",
    question: "Question",

    // form error message
    errorRequired: "This is required",
    internalServerError: "Internal server error, please contact Admin",
    pinLengthTooShort: "Pin required 6 digit",
    pinNotMatch: "Pin not match",
    pinOnlyNumber: "Pin only accept number 0 to 9",
    phoneInvalidFormat: "Phone Format Invalid",
    emailInvalidFormat: "Email Format Invalid",
    noItemFound: "No item found",
    nominationPercentageOverLimit: "Total Allocation Percentage Over 100%",
    noQuestionFound: "No Question Found.",
    noAnswerOptionFound: "No Answer Option Found.",
    formErrorPleaseCheck: "Form error, please recheck",

    // confirmation box message
    deleteConfirmation: "Delete Confirmation Box",
    deleteMessage: "Are you sure to remove this? Action cannot be undone.",
    
    references: "References",
    addNewReferences: "Add New References",
    noReferenceFound: "No References Found",
    referenceType: "Reference Type",
    referenceImageFileTypeHint: "Reference Image Only support these file type: jpg, jpeg, png.",
    noReferenceFileUploaded: "No Reference File uploaded",
    currentReferenceDocument: "Current Reference Document",

    // language
    en: "EN",
    cn: "CN",
    bm: "BM",
    english: "English",
    chinese: "Chinese",
    melayu: "Bahasa Melayu",
    
    //options
    url: "URL",
    image: "Image",

    validateForm: "Validate Form",
    submittingForm: "Submitting Form",
    noPolicySelected: "No Policy Selected.",
    servicedBy: "Serviced By",
    employmentStatus: "Employment Status",
}

export default en;