import Swal from "sweetalert2";
import { translate } from "./translate";

export const promptMessage = (lang, type, msg, optional = {}) => {
    let basic = {
        showConfirmButton: true,
        timer: 2500,
    }
    basic = { ...basic, ...optional }
    if (optional.timer === 0) {
        delete basic.timer;
    }

    return Swal.fire({
        icon: type,
        title: translate(lang, type),
        text: lang === "EN" ? msg.toUpperCase() : msg,
        ...basic
    });
}

export const dataDisplayOptions = (lang) => {
    let options = [
        { id: 1, label: translate(lang, 'ring'), value: "ring", isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'barChart'), value: "barChart", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const genderOptions = (lang) => {
    let options = [
        { id: 1, label: translate(lang, 'male'), value: "male", isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'female'), value: "female", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const relationshipOptions = (lang) => {
    let options = [
        { label: translate(lang, 'parent'), value: "parent", isSelected: false, isHidden: false, },
        { label: translate(lang, 'husbandWife'), value: "husbandWife", isSelected: false, isHidden: false, },
        { label: translate(lang, 'children'), value: "children", isSelected: false, isHidden: false, },
        { label: translate(lang, 'siblings'), value: "siblings", isSelected: false, isHidden: false, },
        { label: translate(lang, 'guardian'), value: "guardian", isSelected: false, isHidden: false, },
        { label: translate(lang, 'friends'), value: "friends", isSelected: false, isHidden: false, },
        { label: translate(lang, 'relative'), value: "relative", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const premiumTypeOptions = (lang) => {
    let options = [
        { label: translate(lang, 'monthly'), value: "monthly", isSelected: false, isHidden: false, },
        { label: translate(lang, 'quarterly'), value: "quarterly", isSelected: false, isHidden: false, },
        { label: translate(lang, 'halfYearly'), value: "halfYearly", isSelected: false, isHidden: false, },
        { label: translate(lang, 'yearly'), value: "yearly", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const questionairType = (lang) => {
    let types = [
        { id: 1, label: translate(lang, 'chooseOne'), value: 'chooseOne', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'chooseMultiple'), value: 'chooseMulti', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'fillIn'), value: 'fillIn', isSelected: false, isHidden: false, },
    ]
    return types.filter(t => t.isHidden === false);
}

export const languageOptions = (lang) => {
    let langs = [
        { id: 1, label: translate(lang, 'chooseOne'), value: 'en', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'chooseOne'), value: 'cn', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'chooseOne'), value: 'bm', isSelected: false, isHidden: false, },
    ]
    return langs.filter(l => l.isHidden === false);
}

export const referenceOptions = (lang) => {
    let langs = [
        { id: 1, label: translate(lang, 'url'), value: 'url', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'image'), value: 'image', isSelected: false, isHidden: true, },
    ]
    return langs.filter(l => l.isHidden === false);
}

export const employmentOptions = (lang) => {
    let opt = [
        { id: 1, label: translate(lang, 'employed'), value: 'employed', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'nonEmployed'), value: 'non_employed', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'retiree'), value: 'retiree', isSelected: false, isHidden: false, },
    ]
    return opt.filter(l => l.isHidden === false);
}

export const typeOptions = (lang) => {
    let opt = [
        { id: 1, label: translate(lang, 'text'), value: 'string', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'number'), value: 'number', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'date'), value: 'date', isSelected: false, isHidden: false, },
        { id: 4, label: translate(lang, 'file'), value: 'file', isSelected: false, isHidden: true, },
        { id: 5, label: translate(lang, 'options'), value: 'option', isSelected: false, isHidden: true, },
        { id: 6, label: translate(lang, 'checkbox'), value: 'boolean', isSelected: false, isHidden: true, },
    ]
    return opt.filter(l => l.isHidden === false);
}

export const numberWithFormat = (number, localStringFormat = "en-US") => {
    if (typeof number === "number") {
        return number.toLocaleString(localStringFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
        if (isNaN(parseFloat(number)) && isNaN(parseInt(number))) {
            return "Invalid number";
        } else {
            return parseFloat(number).toLocaleString(localStringFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    }
}

export const checkStringFormat = (string, format) => {
    if (string.match(format)) {
        return true
    }
    return false;
}

export const selectDropdownStyle = () => {
    return {
        control: (base, state) => ({
            ...base,
            height: "100%",
            border: 0,
            borderColor: "transparent",
            boxShadow: "none",
            "&:hover": {
                borderColor: "transparent",
                boxShadow: "none",
            },
            backgroundColor: "transparent",
        }),
        valueContainer: (base, state) => ({
            ...base,
            paddingLeft: 0,
            paddingRight: 0,
            borderColor: "transparent",
        }),
        indicatorSeparator: (base, state) => ({
            ...base,
            display: 'none',
        })
    }
}

export function ageCalculator(dob, option = {}) {
    let age = 0;
    if (!dob || dob === "") { return age };
    if (typeof dob === 'string') {
        const birthdateDate = new Date(dob);
        const currentDate = new Date();

        // Calculate the difference in milliseconds
        const timeDifference = currentDate - birthdateDate;

        // Calculate age in years
        const ageInYears = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));

        return ageInYears;
    } else {
        // if (Object.prototype.toString.call(dob) === "[object Date]") {
        //     age = DateTime.now().diff(DateTime.fromFormat(dob, option.format), 'years').toObject(); // 2023
        //     return Math.ceil(age.years);
        // }
        return "error";
    }
}