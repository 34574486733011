import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomTable from "../../components/CustomTable";

const MemberPolicy = (props) => {

    const tableColumns = [
        {
            Header: 'No',
            accessor: (row, index) => {
                return index + 1;
            }
        },
    ]
    const [data, setData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "member_policy_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [])

    function getList() {
        let items = [];
        setData(items);
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    
                </div>
                <div className="card-body">
                    <div id="job_data" className="dataTables_wrapper">
                        <CustomTable
                            lang={props.lang} 
                            columns={tableColumns} 
                            data={data} 
                            excelExport={true}
                            fileData={fileData}
                        ></CustomTable>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 

export default connect(mapStateToProps)(MemberPolicy);