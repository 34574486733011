/// Pages

import AgentList from "../jsx/pages/agent/AgentList";
import CreateAgent from "../jsx/pages/agent/CreateAgent";
import CompanyForm from "../jsx/pages/company/CompanyForm";
import CompanyList from "../jsx/pages/company/CompanyList";
import CompanyPolicyForm from "../jsx/pages/company/CompanyPolicyForm";
import CompanyPolicyList from "../jsx/pages/company/CompanyPolicyList";
import Dashboard from "../jsx/pages/Dashboard";
import MemberForm from "../jsx/pages/member/MemberForm";
import MemberList from "../jsx/pages/member/MemberList";
import MemberPolicy from "../jsx/pages/member/MemberPolicy";
import MemberPolicyForm from "../jsx/pages/member/MemberPolicyForm";
import QuestionairForm from "../jsx/pages/questionair/QuestionairForm";
import QuestionairList from "../jsx/pages/questionair/QuestionairList";
import QuestionairResult from "../jsx/pages/questionair/QuestionairResult";
import MasterPolicyForm from "../jsx/pages/settings/MasterPolicyForm";
import MasterPolicySettings from "../jsx/pages/settings/MasterPolicySettings";
import PolicyCategoryForm from "../jsx/pages/settings/PolicyCategoryForm";
import PolicyCategorySettings from "../jsx/pages/settings/PolicyCategorySettings";
import RiderForm from "../jsx/pages/settings/RiderForm";
import RiderSettings from "../jsx/pages/settings/RiderSettings";

const routes =  [
    /// Dashboard
    { url: "", component: Dashboard, regex: 'dashboard', name: "dashboard" },
    { url: "dashboard", component: Dashboard, regex: 'dashboard', name: "dashboard" },
    { url: "member/policy", component: MemberPolicy, regex: 'member', actual: "policy", name: "memberPolicy" },
    { url: "member/policy/:type/:slug?", component: MemberPolicyForm, regex: 'member', actual: "policy", name: "memberPolicy" },
    { url: "member/:type/:slug?", component: MemberForm, regex: 'member', name: "memberList" },
    { url: "member", component: MemberList, regex: 'member', name: "memberList" },
    { url: "agent/:type/:slug?", component: CreateAgent, regex: 'agent', name: "agent" },
    { url: "agent", component: AgentList, regex: 'agent', name: "agent" },
    { url: "settings/master-policy/:type/:slug?", component: MasterPolicyForm, regex: 'master-policy', name: "masterPolicySettings" },
    { url: "settings/master-policy", component: MasterPolicySettings, regex: 'master-policy', name: "masterPolicySettings" },
    { url: "settings/policy-category/:type/:slug?", component: PolicyCategoryForm, regex: 'policy-category', name: "policyCategorySettings" },
    { url: "settings/policy-category", component: PolicyCategorySettings, regex: 'policy-category', name: "policyCategorySettings" },
    { url: "settings/rider", component: RiderSettings, regex: 'rider', name: "rider" },
    { url: "settings/rider/:type/:slug?", component: RiderForm, regex: 'rider', name: "rider" },
    { url: "company/policy/:slug", component: CompanyPolicyList, regex: 'company', actual: "policy", name: "companyPolicySettings" },
    { url: "company/policy/:type/:slug?", component: CompanyPolicyForm, regex: 'company', actual: "policy", name: "companyPolicySettings" },
    { url: "company/:type/:slug?", component: CompanyForm, regex: 'company', name: "companySettings" },
    { url: "company", component: CompanyList, regex: 'company', name: "companySettings" },
    { url: "questionair/result/:slug", component: QuestionairResult, regex: 'questionair', name: "questionnaire" },
    { url: "questionair/:type/:slug?", component: QuestionairForm, regex: 'questionair', name: "questionnaire" },
    { url: "questionair", component: QuestionairList, regex: 'questionair', name: "questionnaire" },
];

export default routes;