import React from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table';
import * as XLSX from 'xlsx';
import moment from "moment";
import { set_cptable } from "xlsx";
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
import { translate } from '../../../utils/translate';
set_cptable(cptable);

function GlobalFilter({
    // preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    lang,
}) {
    // const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="row align-items-center">
            <div className="col-sm-12">
                <input
                    className="form-control form-control-sm"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={translate(lang, 'searchTable')}
                />
            </div>
        </div>
    )
}
// function DefaultColumnFilter({
//     column: { filterValue, preFilteredRows, setFilter },
// }) {
//     const count = preFilteredRows.length

//     return (
//         <input
//             className="form-control"
//             value={filterValue || ''}
//             onChange={e => {
//                 setFilter(e.target.value || undefined)
//             }}
//             placeholder={`Search ${count} records...`}
//         />
//     )
// }

function filterListView(input) {

}

function ListView({data, layout, search = false, lang, onFilterInputChange, emptyTableMessage = "noItemFound"}) {
    // const {
    //     getTableProps,
    //     getTableBodyProps,
    //     headerGroups,
    //     rows,
    //     prepareRow,
    //     state,
    //     preGlobalFilteredRows,
    //     setGlobalFilter,

    //     page,
    //     canPreviousPage,
    //     canNextPage,
    //     pageOptions,
    //     pageCount,
    //     gotoPage,
    //     nextPage,
    //     previousPage,
    //     setPageSize,
    //     state: { pageIndex, pageSize },
    // } = useTable(
    //     {
    //         columns,
    //         data,
    //         initialState: { pageIndex: 0 },
    //     },
    //     useFilters,
    //     useGlobalFilter,
    //     useSortBy,
    //     usePagination,
    // )
    
    // Render the UI for your table
    return (
        <>
            <div className='d-flex flex-column h-100'>
                {search ? (
                    <div className='col-12'>
                        <input type="text" onChange={(e) => onFilterInputChange(e.target.value)} className="form-control form-control-sm mb-3" placeholder={translate(lang, "searchHere")}/>
                    </div>
                ) : <></>
                }
                <div className="h-100 list-view-container mb-2 px-2">
                    {data.length > 0 ?
                        layout: 
                        translate(lang, emptyTableMessage)
                    }
                    {/* {layout} */}
                </div>
            </div>
        </>
    )
}

export default ListView;