import React from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table';
import * as XLSX from 'xlsx';
import moment from "moment";
import { set_cptable } from "xlsx";
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
import { translate } from '../../../utils/translate';
set_cptable(cptable);

function GlobalFilter({
    // preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    lang,
}) {
    // const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="row align-items-center">
            <div className="col-sm-12">
                <input
                    className="form-control form-control-sm"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={translate(lang,'searchTable')}
                />
            </div>
        </div>
    )
}

// function DefaultColumnFilter({
//     column: { filterValue, preFilteredRows, setFilter },
// }) {
//     const count = preFilteredRows.length

//     return (
//         <input
//             className="form-control"
//             value={filterValue || ''}
//             onChange={e => {
//                 setFilter(e.target.value || undefined)
//             }}
//             placeholder={`Search ${count} records...`}
//         />
//     )
// }


function CustomTable({ columns, data, lang, disable_filter=false, excelExport=false, fileData={}}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    const exportExcel = () => {
        let exFileName = `${fileData.name}_${moment().unix().toString()}.xlsx`;
        // let temp = data.map((i) => { return {date: moment(i.createdAt).format("YYYY-MM-DD HH:mm:ss"), commission: i.commission, remarks: i.remarks}})
        // var wb = XLSX.utils.book_new();
        console.log(fileData.excelData);
        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(fileData.excelData && fileData.excelData.length > 0 ? fileData.excelData : []);
        if (fileData.hasCustom) {
            fileData.customRows.map((cr) => {
                XLSX.utils.sheet_add_aoa(ws, [cr.customData], {origin: cr.origin ?? -1})
            })
        }
        XLSX.utils.book_append_sheet(wb, ws, "Report");
        XLSX.writeFile(wb, exFileName)
    }

    // Render the UI for your table
    return (
        <>
            <div className='d-flex flex-column flex-md-row mt-3'>
                <div className='table-plugin-top-left flex-grow-1 col-12 col-md-6'>
                    <div>
                    {
                        excelExport ? (<>
                            <button className="btn table-export-button-excel btn-sm" onClick={(e) => exportExcel(e)}>{translate(lang, 'exportExcel')}</button>
                        </>) : <></>
                    }
                    </div>
                </div>
                <div className='table-plugin-top-right flex-grow-1 flex-md-grow-0 ms-auto col-12 col-md-3'>
                    {
                        disable_filter ? (<></>
                        ) : (
                            <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            lang={lang}
                            />
                        )
                    }
                </div>
            </div>
            <div className="sorting-table-container mb-2">
                <table className="table table-responsive dataTable display" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header').toUpperCase()}
                                        <span className="ml-1">
                                            {column.isSorted ? (column.isSortedDesc ?  <i className="fa fa-arrow-down" /> :  <i className="fa fa-arrow-up" /> ) : '' }
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}  
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                            )
                        })}
                        {rows.length === 0 ? (
                            <tr role="row">
                                <td colSpan={columns.length}>{translate(lang, 'noResultFound')}</td>
                            </tr>
                        ) : null}
                        {/* {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>
                            )
                        })} */}
                    </tbody>
                </table>
            </div>
            <div className="d-flex flex-column flex-md-row">
                <div className="dataTables_paginate paging_simple_numbers pagination ">
                    <button className="paginate_button previous me-2 flex-grow-1 flex-md-grow-0" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                    <button className="paginate_button previous me-2 flex-grow-1 flex-md-grow-0" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </button>
                    <div className="dataTables_info me-2 pt-2">
                        {translate(lang, 'page')} <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </div>
                    <button className="paginate_button next me-2 flex-grow-1 flex-md-grow-0" onClick={() => nextPage()} disabled={!canNextPage}>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                    <button className="paginate_button next me-2 flex-grow-1 flex-md-grow-0" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                    </button>
                    
                    {/* <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span> */}
                </div>
                <div className="d-flex flex-row ms-0 ms-md-auto">
                    <div className="me-2 align-items-center mt-1">
                        <span style={{ backgroundColor: "transparent"}}>{translate(lang, 'goToPage')} </span>{' '}
                        <input
                            className="form-control-sm"
                            type="number"
                            defaultValue={pageIndex + 1}
                            max={pageOptions.length}
                            min={1}
                            onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '50px', borderWidth: 1, borderColor: '#dddddd' }}
                        />
                    </div>
                    <select
                        className='ms-auto'
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                        {translate(lang, 'show')} {pageSize}
                        </option>
                    ))}
                    </select>
                </div>
            </div>
        </>
    )
}

export default CustomTable;