import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createMasterPolicy, editMasterPolicy, getMasterPolicyList } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { dataDisplayOptions, promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';

const MasterPolicyForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const displayTypeOptions = dataDisplayOptions(props.lang);
    const { type, slug } = useParams();
    const [data, setData] = useState({
        name: "",
        key: "",
        displayType: "",
        icon: "",
    });
    const inputEl = useRef(null);
    const [file, setFile] = useState([]);
    const [error, setError] = useState({
        name: "",
        displayType: "",
        key: "",
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                name: "",
                displayType: "",
                key: "",
                icon: "",
            })
        } else if (type === 'view') {
            if (!slug) {
                history.push('/settings/master-policy');
                return;
            }
            getDetails(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/settings/master-policy');
                return;
            }
            getDetails(slug);
        }
    }, [])

    const getDetails = async (slug) => {
        let params = { slug: slug };
        await getMasterPolicyList(params).then((response) => {
            const result = response.data[0];
            setData({
                id: result.id,
                name: result.name,
                key: result.key,
                displayType: result.dataDisplay,
                level: result.level,
                icon: result.icon,
            })
        }).catch((err) => {
            console.log(err);
            history.push('/settings/master-policy')
        })
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    const onFileChange = (event) => {
        setFile(event.target.files);
    };

    const submitForm = async () => {
        let check = true;
        let error = { name: "", displayType: "", key: "", file: "" };
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired")
        }
        if (!data.key) {
            check = false;
            error.key = translate(lang, "errorRequired")
        }
        if (!data.displayType) {
            check = false;
            error.displayType = translate(lang, "errorRequired")
        }
        if (['create'].includes(type)) {
            if (!file || file.length < 1) {
                check = false;
                error.file = translate(lang, "errorRequired")
            }
        }
        setError({ ...error });
        if (check) {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("key", data.key);
            formData.append("dataDisplay", data.displayType);
            if (file && file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formData.append("attachments", file[i], file[i].name);
                }
            }
            if (type === 'create') {
                await createMasterPolicy(formData).then((res) => {
                    console.log(res.status)
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/master-policy');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type === 'edit') {
                let id = data.id;
                delete data.id;
                await editMasterPolicy(formData, id).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/master-policy');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }


    return (
        <>
            <div className="card shadow col-12">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "detailsMasterPolicy" : type === "edit" ? "editMasterPolicy" : "createMasterPolicy"))}
                    </h3>
                </div>
                <div className="card-body col-12 col-md-7 d-flex flex-column flex-wrap">
                    <div className="form-group py-2">
                        <label className="form-label required">{translate(lang, 'name')}</label>
                        <input type="text" name="name" value={data.name} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        {error.name !== "" ? <smalL className="text-danger">{error.name}</smalL> : <></>}
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label required">{translate(lang, 'code')}</label>
                        <input type="text" name="key" value={data.key} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        {error.key !== "" ? <smalL className="text-danger">{error.key}</smalL> : <></>}
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label required">{translate(lang, 'displayType')}</label>
                        <Select
                            className="form-control"
                            value={displayTypeOptions.filter(
                                (option) => option.value === data.displayType
                            )}
                            isDisabled={type === 'view' ? true : false}
                            onChange={(e) => handleOptionSelected(e, "displayType")}
                            options={displayTypeOptions}
                            placeholder={translate(lang, "placeholderChoose")}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    height: "100%",
                                    border: 0,
                                    borderColor: "transparent",
                                    boxShadow: "none",
                                    "&:hover": {
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                    },
                                    backgroundColor: "transparent",
                                }),
                                valueContainer: (base, state) => ({
                                    ...base,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    borderColor: "transparent",
                                })
                            }}
                        />
                        {error.displayType !== "" ? <smalL className="text-danger">{error.displayType}</smalL> : <></>}
                        {/* <input type="text" value={data.displayType} onChange={(e) => { setData({ ...data, name: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                    </div>
                    {/* <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'level')}</label>
                        <input type="number" min="0" step="1" value={data.level} onChange={(e) => { setData({ ...data, level: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div> */}
                    <div className="py-2">
                        <div className="form-group" hidden={type === 'view' ? true : false}>
                            <label className="form-label required">{translate(lang, 'icon')}</label>
                            <input type="file" className="form-control h-100" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} onChange={(e) => onFileChange(e)} ref={inputEl} />
                            {error.file !== "" ? <smalL className="text-danger">{error.file}</smalL> : <></>}
                        </div>
                        {type !== 'create' ? (<div className="mt-2">
                            <label className="form-label">{translate(lang, 'currentIcon')}</label>
                            <div className={`icon-box rounded-2`}>
                                <img src={`${api.policyMasterIcon}/${data.icon}`} alt="logo" className="w-100 h-100 rounded-2" />
                            </div>
                        </div>) : <></>}
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-sm btn-primary" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        <button type="button" className="btn btn-sm btn-dark mx-2" onClick={() => history.push('/settings/master-policy')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(MasterPolicyForm);