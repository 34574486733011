import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Pie,Bar } from 'react-chartjs-2';
import { translate } from "../../utils/translate";
import { getAdminDashboard } from "../../services/ApiService";

const Dashboard = (props) => {
    const [stats, setStats] = useState({
        totalCustomer: 0,
        totalAgent: 0,
        genderRatio: {
            male: 0,
            female: 0
        },
        companyStats: []
    });

    async function getDashboardStats() {
        let query = {};
        let res = await getAdminDashboard(query);
        if (res?.status === 'success') {
            const { totalAgent,totalCustomer, totalGender, companyStats } = res.data;
            setStats({
                totalCustomer,
                totalAgent,
                genderRatio: totalGender,
                companyStats
            });
        }
    }

    useEffect(() => {
        getDashboardStats();
    }, []);

    const genderData = {
        labels: ['Male', 'Female'],
        datasets: [
            {
                label: 'Gender Distribution',
                data: [stats.genderRatio.male, stats.genderRatio.female],
                backgroundColor: ['#36A2EB', '#FF6384'], // Blue, Pink
                hoverBackgroundColor: ['#36A2EB', '#FF6384']
            }
        ]
    };

    const companyData = {
        labels: stats.companyStats.map(stat => stat.companyName),
        datasets: [
            {
                label: 'Company Distribution',
                data: stats.companyStats.map(stat => stat.userCount),
                backgroundColor: [
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                    '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'
                ],
                hoverBackgroundColor: [
                    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                    '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'
                ]
            }
        ]
    };

    return (
        <>
            <div className="row">
                {props.auth.auth.data.role === "admin" ? (
                    <>
                        {/* Status Cards */}
                        <div className="col-xl-6 col-sm-6">
                            <div className="card shadow-black card-bx" style={{ border: '1px solid #17a2b8' }}>
                                <div className="card-body d-flex align-items-center">
                                    <div className="me-auto" style={{ color: '#17a2b8' }}>
                                        <h3 className="text-primary">{stats.totalCustomer}</h3>
                                        <span className="fs-18">Total Customer</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-sm-6">
                            <div className="card shadow-black card-bx" style={{ border: '1px solid #17a2b8' }}>
                                <div className="card-body d-flex align-items-center">
                                    <div className="me-auto" style={{ color: '#28a745' }}>
                                        <h3 className="text-success">{stats.totalAgent}</h3>
                                        <span className="fs-18">Total Agent</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Pie Charts */}
                        <div className="col-xl-6 col-sm-12">
                            <div className="card shadow-black card-bx" style={{ border: '1px solid #17a2b8' }}>
                                <div className="card-body">
                                    <h5 className="card-title">Company Distribution</h5>
                                    <Bar data={companyData} options={{ responsive: true }} />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-sm-12">
                            <div className="card shadow-black card-bx" style={{ border: '1px solid #17a2b8' }}>
                                <div className="card-body">
                                    <h5 className="card-title">Gender Distribution</h5>
                                    <Pie data={genderData} />
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    };
};

export default connect(mapStateToProps)(Dashboard);
