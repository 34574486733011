import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getMasterPolicyList, removeMasterPolicy } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";

const MasterPolicySettings = (props) => {
    const lang = props.lang;
    const tableColumns = [
        {
            Header: translate(lang, 'index'),
            accessor: (row, index) => {
                return index + 1;
            }
        },  
        {
            Header: translate(lang, 'name'),
            accessor: (row, index) => {
                return row.name;
            }
        },
        {
            Header: translate(lang, 'displayType'),
            accessor: (row, index) => {
                return row.dataDisplay === "ring" ? "Ring" : "Bar Chart";
            }
        },
        {
            Header: translate(lang, 'term'),
            accessor: (row, index) => {
                return row.key;
            }
        },
        {
            Header: translate(lang, 'icon'),
            accessor: (row, index) => {
                return <><img src={`${api.policyMasterIcon + "/" + row.icon}`} alt="icon" className="rounded-2" width="50px" height="50px"></img></>;
            }
        },
        {
            Header: translate(lang, 'action'),
            accessor: (row, index) => {
                return (<>
                    <Link
                        to={`/settings/master-policy/view/${row.slug}`}
                        className="btn btn-primary shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-eye"></i>
                    </Link>
                    <Link
                        to={`/settings/master-policy/edit/${row.slug}`}
                        className="btn btn-warning shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-pen"></i>
                    </Link>
                    <span
                        onClick={() => removeItem(row.id)}
                        className="btn btn-danger shadow btn-xs sharp"
                    >
                        <i className="fa fa-trash"></i>
                    </span>
                </>)
            }
        },
    ]
    const [data, setData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "master_policy",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        let query = {};
        let res = await getMasterPolicyList(query);
        if (res?.status === 'success') {
            setData([...res.data])
            setFileData([...res.data])
        } else {
        }
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang,"deleteConfirmation"),
            text: translate(props.lang,"deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeMasterPolicy(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                    getList();
                }
            }
        })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div>
                        <Link to="/settings/master-policy/create" className="btn btn-primary btn-sm">{translate(lang, 'createMasterPolicy')}</Link>
                    </div>
                </div>
                <div className="card-body">
                    <div id="job_data" className="dataTables_wrapper">
                        <CustomTable
                            lang={props.lang}
                            columns={tableColumns}
                            data={data}
                            excelExport={false}
                            fileData={fileData}
                        ></CustomTable>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(MasterPolicySettings);