import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createCustomer, createMasterPolicy, editCustomer, editMasterPolicy, getMasterPolicyList, getUserList, getUserPolicyList } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { genderOptions, promptMessage, numberWithFormat, checkStringFormat, employmentOptions, ageCalculator } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import country from "../../../utils/country";
import ListView from "../../components/ListView";
import { Collapse, UncontrolledCollapse } from "reactstrap";
import Swal from "sweetalert2";


const MemberForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== "" && c.value !== "+"));
    const [employOptions, setEmployOptions] = useState(employmentOptions);
    const { type, slug } = useParams();
    const [data, setData] = useState({
        fullName: "",
        gender: "male",
        age: "",
        identityCard: "",
        birthDate: "",
        email: "",
        phoneCode: "+60",
        phone: "",
        address: "",
        profileImage: "",
        annualIncome: "",
        monthlyCommitment: "",
        retireeIncomeMonthly: "",
        employmentStatus: "",
        agent: "",
    });
    const inputEl = useRef(null);
    const [isNominationOpen, setIsNominationOpen] = useState(false);
    const [genderListOptions, setGenderListOptions] = useState(genderOptions);
    const [agentOptions, setAgentOptions] = useState([]);
    const [isRiderOpen, setIsRiderOpen] = useState(false);
    const [file, setFile] = useState([]);
    const [userPolicyList, setUserPolicyList] = useState([]);
    const [userPolicyListFilter, setUserPolicyListFilter] = useState([]);
    const [error, setError] = useState({
        fullName: "",
        gender: "",
        age: "",
        identityCard: "",
        birthDate: "",
        email: "",
        phoneCode: "",
        phone: "",
        address: "",
        pin: "",
        confirmPin: "",
        annualIncome: "",
        monthlyCommitment: "",
        retireeIncomeMonthly: "",
        employmentStatus: ""
    });

    useEffect(() => {
        getAgentList();
        if (type === 'create') {
            setData({
                fullName: "",
                gender: "male",
                age: "",
                identityCard: "",
                birthDate: "",
                email: "",
                phoneCode: "+60",
                phone: "",
                address: "",
                profileImage: "",
                pin: "",
                confirmPin: "",
                annualIncome: "",
                monthlyCommitment: "",
                retireeIncomeMonthly: "",
                employmentStatus: "",
                agent: "",
            })
        } else if (type === 'view') {
            if (!slug) {
                history.push('/member');
                return;
            }
            getDetails(slug);
            getUserPolicies(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/member');
                return;
            }
            getDetails(slug);
            getUserPolicies(slug);
        }
    }, [])

    const getDetails = async (slug) => {
        let params = { id: slug };
        await getUserList(params).then((response) => {
            const result = response.data[0];
            setData({ ...result, agent: result.agentInCharge.id, age: ageCalculator(result.birthDate) })
            // setData({
            //     id: result.id,
            //     name: result.name,
            //     key: result.key,
            //     displayType: result.dataDisplay,
            //     level: result.level,
            //     icon: result.icon,
            // })
        }).catch((err) => {
            console.log(err);
            history.push('/member')
        })
    }

    const getUserPolicies = async (slug) => {
        let params = { userId: slug };
        let res = await getUserPolicyList(params);
        if (res.status === 'success') {
            const result = res.data;
            setUserPolicyList([...result]);
            setUserPolicyListFilter([...result]);
        } else {
            console.log(res);
        }
    }

    const getAgentList = async () => {
        let query = { isAgent: 1 };
        let res = await getUserList(query);
        if (res?.status === 'success') {
            setAgentOptions([...res.data.map(ag => ({
                fullName: ag.fullName,
                label: ag.fullName,
                value: ag.id,
                id: ag.id,
            }))])
        } else {
        }
    }

    const listViewLayout = (list) => {
        return (
            <>
                <div className="d-flex flex-column card shadow-sm member-policy-container-listview">
                    {list.map((i, index) => {
                        let upcd = i.user_policy_category_details[0].value;
                        return (<>
                            <div className="d-flex flex-column flex-wrap p-3" key={index}>
                                <div className="d-flex flex-column flex-md-row flex-wrap">
                                    {upcd.map((cd) => {
                                        if (['sum_insured', 'sum_assured', 'vehicle_sum_insured'].includes(cd.keyword)) {
                                            return (<div className="col-12 col-md-6 d-flex flex-row flex-wrap gap-1">
                                                <b>{cd.label}:</b>
                                                <span>RM {numberWithFormat(i.user_policy_assureds.length > 0 ? i.user_policy_assureds.reduce((acc, item) => acc += item.amount, 0) : 0)}</span>
                                            </div>)
                                        } else {
                                            return (<div className="col-12 col-md-6 d-flex flex-row flex-wrap gap-1">
                                                <b>{cd.label}:</b>
                                                <span>{cd.value}</span>
                                            </div>)
                                        }
                                    })}
                                    {/* <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'policyNumber')} :</b>
                                        <span>{i.policyNumber}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'company')} :</b>
                                        <span>{i.company.companyName}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'masterPolicy')} :</b>
                                        <span>{i.policy_category.policy_master.name}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'type')} :</b>
                                        <span>{i.policy_category.name}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'totalSumAssured')} :</b>
                                        <span>RM {numberWithFormat(i.totalSumAssured)}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'premiumAmount')} :</b>
                                        <span>RM {numberWithFormat(i.premiumAmount)}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'commencementDate')} :</b>
                                        <span>{i.commencementDate}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'premiumPaymentTerm')} :</b>
                                        <span>{i.premiumPaymentTerm ?? "-"}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'policyTerms')} :</b>
                                        <span>{i.policyTerms ?? "-"}</span></div>
                                    <div className="col-12 col-md-6 d-flex flex-column"><b>{translate(lang, 'expireDate')} :</b>
                                        <span>{i.expiryDate !== "" ? i.expiryDate : "-"}</span></div> */}
                                </div>
                                <hr></hr>
                                <button type="button" className="btn d-flex cursor-pointer px-0 collapse-custom-header-container" id={`assuredCollapse-${index}`}>
                                    <span className="px-3">{translate(lang, 'sumAssuredList')}</span>
                                    {/* <span className="ms-auto me-2"><i className={`fas fa-${isNominationOpen ? "chevron-up" : "chevron-down"}`}></i></span> */}
                                </button>
                                <UncontrolledCollapse className="collapse-custom-body-container" toggler={`#assuredCollapse-${index}`}>
                                    <div className="d-flex flex-column">
                                        {i.user_policy_assureds.length > 0 ? <>
                                            {i.user_policy_assureds.map((upa, upnI) => {
                                                let upa_pc = upa.policy_category;
                                                console.log(i.user_policy_assureds)
                                                return (<div className="d-flex flex-row gap-2 px-2 py-2" key={upnI}>
                                                    <div className={`icon-box rounded-2`}>
                                                        {upa_pc.icon ? <img src={`${api.policyCategoryIcon}/${upa_pc.icon}`} alt="logo" className="w-100 h-100 rounded-2" /> : <></>}
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <b>{upa_pc.name}</b>
                                                        <span>RM {numberWithFormat(upa.amount)}</span>
                                                    </div>
                                                </div>)
                                            })}
                                        </> : <><p className="px-2 py-1">{translate(lang, 'noSumAssuredFounded')}</p></>}
                                    </div>
                                </UncontrolledCollapse>
                                <button type="button" className="btn d-flex cursor-pointer px-0 collapse-custom-header-container" id={`nominationCollapse-${index}`}>
                                    <span className="px-3">{translate(lang, 'nominationList')}</span>
                                    {/* <span className="ms-auto me-2"><i className={`fas fa-${isNominationOpen ? "chevron-up" : "chevron-down"}`}></i></span> */}
                                </button>
                                <UncontrolledCollapse className="collapse-custom-body-container" toggler={`#nominationCollapse-${index}`}>
                                    <div className="d-flex flex-column flex-md-row">
                                        {i.user_policy_nominations.length > 0 ? <>
                                            {i.user_policy_nominations.map((upn, upnI) => (<div className="d-flex flex-column" key={upnI}>
                                                <div className="d-flex flex-column flex-md-row flex-wrap px-2 py-1">
                                                    <div className="col-12 col-md-6">
                                                        <b>{translate(lang, 'fullName')}</b>: {upn.fullName}
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <b>{translate(lang, 'identityCard')}</b>: {upn.identityCard}
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <b>{translate(lang, 'relationship')}</b>: {upn.relationship}
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <b>{translate(lang, 'allocationPercentage')}</b>: {upn.allocationPercentage}
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <b>{translate(lang, 'phone')}</b>: {upn.phoneCode + " " + upn.phone}
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <b>{translate(lang, 'email')}</b>: {upn.email}
                                                    </div>
                                                </div>
                                            </div>))}
                                        </> : <><p className="px-2 py-1">{translate(lang, 'noNominationAssigned')}</p></>}
                                    </div>
                                </UncontrolledCollapse>
                                <button type="button" className="btn d-flex cursor-pointer px-0 collapse-custom-header-container" id={`riderCollapse-${index}`}>
                                    <span className="px-3">{translate(lang, 'riderList')}</span>
                                    {/* <span className="ms-auto me-2"><i className={`fas fa-${isRiderOpen ? "chevron-up" : "chevron-down"}`}></i></span> */}
                                </button>
                                <UncontrolledCollapse className="collapse-custom-body-container" toggler={`#riderCollapse-${index}`}>
                                    <div className="d-flex flex-column flex-md-row">
                                        {i.user_policy_riders.length > 0 ? <>
                                            {i.user_policy_riders.map((upr, uprI) => (<div className="d-flex flex-column" key={uprI}>
                                                <div className="d-flex flex-column flex-md-row flex-wrap px-2 py-1">
                                                    <span className="badge badge-primary">{upr.policy_rider.rider.name}</span>
                                                </div>
                                            </div>))
                                            }
                                        </> : <><p className="px-2 py-1">{translate(lang, 'noPolicyRiderFoundOnThisUser')}</p></>}
                                    </div>
                                </UncontrolledCollapse>
                            </div>
                        </>)
                    })}
                </div>
            </>
        )
    }

    const onFilterInputChange = (input) => {
        let temp;
        if (input !== "") {
            temp = userPolicyList.filter(a => {
                // a = a.flat();
                let found = false;
                let keys = Object.keys(a);
                let regex = new RegExp(input, "");
                return keys.find(k => {
                    // if(a[k] && a[k].toString().toLowerCase().match(regex).length > 0) return a;
                    if (a[k] && typeof a[k] === 'object') {
                        let keysArr = Object.keys(a[k]);
                        if (keysArr.length > 0) {
                            keysArr.find(ka => {
                                if (a[k][ka] && a[k][ka].toString().toLowerCase().match(regex) && a[k][ka].toString().toLowerCase().match(regex).length > 0) {
                                    found = true;
                                }
                            })
                        }
                    } else {
                        if (a[k] && a[k].toString().toLowerCase().match(regex) && a[k].toString().toLowerCase().match(regex).length > 0) found = true;
                    }
                    if (found) {
                        return a;
                    }
                })

                // if (found) {
                //     console.log("found");
                //     return a;
                // }
                return false;
            })
        } else {
            temp = userPolicyList;
        }
        setUserPolicyListFilter([...temp])
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        if (name === 'birthDate') {
            let age = ageCalculator(value);
            console.log(age);
            temp['age'] = age;
        }
        temp[name] = value;
        setData({ ...temp });
    };

    const onFileChange = (event) => {
        if (type === 'view') return;
        setFile(event.target.files);
    };

    const submitForm = async () => {
        let check = true;
        promptMessage(lang, 'info', translate(lang, 'validateForm'), {
            timer: 1000,
            didOpen: () => {
                Swal.showLoading()
                let error = { fullName: "", gender: "", age: "", birthDate: "", phoneCode: "", phone: "", pin: "", confirmPin: "", annualIncome: "", monthlyCommitment: "", retireeIncomeMonthly: "", employmentStatus: "" };
                [...Object.keys(error)].forEach((key) => {
                    if (type === 'edit' && ['pin', 'confirmPin'].includes(key)) {
                        // skip not showing data
                        return;
                    }
                    else if (data[key] === "" || data[key] === undefined || data[key] === null) {
                        check = false;
                        error[key] = translate(lang, "errorRequired")
                    }
                    if (type === 'create') {
                        if (data['pin'] !== data['confirmPin']) {
                            check = false;
                            error['pin'] = translate(lang, "pinNotMatch")
                            error['confirmPin'] = translate(lang, "pinNotMatch")
                        }
                        if (data['pin'].length < 6) {
                            check = false;
                            error['pin'] = translate(lang, "pinLengthTooShort")
                        }
                        if (data['confirmPin'].length < 6) {
                            check = false;
                            error['confirmPin'] = translate(lang, "pinLengthTooShort")
                        }
                    }
                    if (data['email'] && !checkStringFormat(data['email'], /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/g)) {
                        check = false
                        error["email"] = translate(lang, "emailInvalidFormat")
                    }
                })
                setError({ ...error });
                console.log(error);
                return check;
            },
            didDestroy: () => {
                Swal.hideLoading()
            },
        }).then(async (result) => {
            if (check) {
                if (type === 'create') {
                    await createCustomer(data).then((res) => {
                        if (res.status === 'success') {
                            promptMessage(lang, 'success', res.message)
                            history.push('/member');
                        } else {
                            promptMessage(lang, 'error', res.message)
                        }
                    }).catch((error) => {
                        promptMessage(lang, 'error', error);
                    })
                } else if (type === 'edit') {
                    await editCustomer(slug, data).then((res) => {
                        Swal.close()
                        if (res.status === 'success') {
                            promptMessage(lang, 'success', res.message)
                            history.push('/member');
                        } else {
                            promptMessage(lang, 'error', res.message)
                        }
                    }).catch((error) => {
                        Swal.close()
                        promptMessage(lang, 'error', error);
                    })
                }
            } else {
                promptMessage(lang, 'error', translate(lang, 'formErrorPleaseCheck'));
            }
        })
    }


    return (
        <>
            <div className="d-flex flex-column">
                <div className="col-12 px-2">
                    <div className="card shadow col-12">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "viewMember" : type === "edit" ? "editMember" : "createMember"))}
                            </h3>
                        </div>
                        <div className="card-body col-12">
                            <div className="col-12 d-flex flex-row flex-wrap">
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'fullName')}</label>
                                    <input type="text" value={data.fullName} name="fullName" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.fullName == "" ? <></> : <p className="text-danger">{error.fullName}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'gender')}</label>
                                    {type === "view" ? <>
                                        <input type="text" value={data.gender ?? ""} name="gender" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    </> : <Select
                                        className="form-control"
                                        value={genderListOptions.filter(
                                            (option) => option.value === data.gender
                                        )}
                                        isDisabled={type === 'view' ? true : false}
                                        onChange={(e) => handleOptionSelected(e, "gender")}
                                        options={genderListOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                            })
                                        }}
                                    />}
                                    {error.gender == "" ? <></> : <p className="text-danger">{error.gender}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'age')}</label>
                                    <input type="number" value={data.age ?? ""} name="age" className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} readOnly />
                                    {error.age == "" ? <></> : <p className="text-danger">{error.age}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'birthDate')}</label>
                                    <input type="date" value={data.birthDate ?? ""} name="birthDate" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.birthDate == "" ? <></> : <p className="text-danger">{error.birthDate}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'identityCard')}</label>
                                    <input type="text" value={data.identityCard ?? ""} name="identityCard" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.identityCard == "" ? <></> : <p className="text-danger">{error.identityCard}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'email')}</label>
                                    <input type="email" value={data.email ?? ""} name="email" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.email == "" ? <></> : <p className="text-danger">{error.email}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'phoneCode')}</label>
                                    <Select
                                        className="form-control"
                                        value={phoneCodeOptions.filter((option) => {
                                            return option.value === data.phoneCode
                                        }
                                        )}
                                        isDisabled={type === 'view' ? true : false}
                                        onChange={(e) => handleOptionSelected(e, "phoneCode")}
                                        options={phoneCodeOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                            })
                                        }}
                                    />
                                    {error.phoneCode == "" ? <></> : <p className="text-danger">{error.phoneCode}</p>}
                                    {/* <input type="text" value={data.phoneCode} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'phone')}</label>
                                    <input type="text" value={data.phone ?? ""} name="phone" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.phone == "" ? <></> : <p className="text-danger">{error.phone}</p>}
                                </div>
                                {type !== 'create' ? <></> : <>
                                    <div className="form-group py-2 col-12 col-md-6 px-2">
                                        <label className="form-label required">{translate(lang, 'pin')}</label>
                                        <input type="pin" inputMode="numeric" pattern="[0-9]{6}" maxLength={6} value={data.pin ?? ""} name="pin" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.pin == "" ? <></> : <p className="text-danger">{error.pin}</p>}
                                    </div>
                                    <div className="form-group py-2 col-12 col-md-6 px-2">
                                        <label className="form-label required">{translate(lang, 'confirmPin')}</label>
                                        <input type="pinpassword" inputMode="numeric" pattern="[0-9]{6}" maxLength={6} value={data.confirmPin ?? ""} name="confirmPin" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.confirmPin == "" ? <></> : <p className="text-danger">{error.confirmPin}</p>}
                                    </div>
                                </>}
                                {/* <div className="form-group py-2">
                            <label className="form-label">{translate(lang, 'level')}</label>
                            <input type="number" min="0" step="1" value={data.level} onChange={(e) => { setData({ ...data, level: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                        </div> */}
                                <div className="py-2 px-2 col-12 d-flex flex-column flex-lg-row">
                                    {/* <div hidden={type === 'view' ? true : false}>
                                        <label className="form-label">{translate(lang, 'profile')}</label>
                                        <input type="file" className="form-control h-100" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} onChange={(e) => onFileChange(e)} ref={inputEl} />
                                    </div> */}
                                    {type !== 'create' ? (<div className="mt-2 col-6">
                                        <label className="form-label">{translate(lang, 'currentProfile')}</label>
                                        <div className={`profile-image-box`}>
                                            {data.profileImage ?
                                                <img src={`${api.userProfile}/${data.profileImage}`} alt="profile" className="w-100 h-100" />
                                                : <></>
                                            }
                                        </div>
                                    </div>) : <></>}
                                    {type === 'create' ? <></> :
                                        <div className="form-group py-2 col-6 px-2">
                                            <label className="form-label">{translate(lang, 'agent')}</label>
                                            <Select
                                                className="form-control"
                                                value={agentOptions.filter((option) => {
                                                    return option.value === data.agent
                                                }
                                                )}
                                                isDisabled={type === 'view' ? true : false}
                                                onChange={(e) => handleOptionSelected(e, "agent")}
                                                options={agentOptions}
                                                placeholder={translate(lang, "placeholderChoose")}
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        height: "100%",
                                                        border: 0,
                                                        borderColor: "transparent",
                                                        boxShadow: "none",
                                                        "&:hover": {
                                                            borderColor: "transparent",
                                                            boxShadow: "none",
                                                        },
                                                        backgroundColor: "transparent",
                                                    }),
                                                    valueContainer: (base, state) => ({
                                                        ...base,
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                        borderColor: "transparent",
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="form-group py-2 col-12 px-2">
                                    <label className="form-label">{translate(lang, 'address')}</label>
                                    <input type="text" value={data.address ?? ""} name="address" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.address == "" ? <></> : <p className="text-danger">{error.address}</p>}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="col-12 d-flex flex-row flex-wrap">
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'annualIncome')}</label>
                                    <input type="number" min={0} onWheel={(e) => e.target.blur()} value={data.annualIncome} name="annualIncome" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.annualIncome == "" ? <></> : <p className="text-danger">{error.annualIncome}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'monthlyCommitment')}</label>
                                    <input type="number" min={0} onWheel={(e) => e.target.blur()} value={data.monthlyCommitment} name="monthlyCommitment" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.monthlyCommitment == "" ? <></> : <p className="text-danger">{error.monthlyCommitment}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, 'retireeIncomeMonthly')}</label>
                                    <input type="number" min={0} onWheel={(e) => e.target.blur()} value={data.retireeIncomeMonthly} name="retireeIncomeMonthly" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.retireeIncomeMonthly == "" ? <></> : <p className="text-danger">{error.retireeIncomeMonthly}</p>}
                                </div>
                                <div className="form-group py-2 col-12 col-md-6 px-2">
                                    <label className="form-label required">{translate(lang, "employmentStatus")}</label>
                                    <Select
                                        className="form-control"
                                        value={employOptions.filter((option) => {
                                            return option.value === data.employmentStatus
                                        }
                                        )}
                                        isDisabled={type === 'view' ? true : false}
                                        onChange={(e) => handleOptionSelected(e, "employmentStatus")}
                                        options={employOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                            })
                                        }}
                                    />
                                    {error.employmentStatus == "" ? <></> : <p className="text-danger">{error.employmentStatus}</p>}
                                    {/* <input type="text" value={data.phoneCode} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row gap-3">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                                <button type="button" className="btn btn-dark btn-sm" onClick={() => history.push('/member')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {type !== 'view' ? <></> : (
                    <div className="col-12 px-2">
                        <div className="card shadow">
                            <div className="card-header">
                                <h3 className="m-0">
                                    {translate(lang, "policyList")}
                                </h3>
                            </div>
                            <div className="card-body d-flex flex-column h-25-vh" style={{ minHeight: '700px' }}>
                                {userPolicyList.length > 0 ?
                                    <ListView data={userPolicyListFilter} onFilterInputChange={(input) => onFilterInputChange(input)} layout={listViewLayout(userPolicyListFilter)} search={true} lang={props.lang}></ListView>
                                    : <p>{translate(lang, "noPolicyFoundOnThisUser")}</p>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(MemberForm);