import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createMasterPolicy, editMasterPolicy, getCompanyList, getCompanyPolicyList, getMasterPolicyList, getPolicyCategoryList } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { dataDisplayOptions, promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';

const CompanyPolicyForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const [policyCategoryOptions, setPolicyCategoryOptions] = useState([]);
    const { type, slug } = useParams();
    const [data, setData] = useState({
        policyName: "",
        categoryId: "",
        companyId: "",
        icon: "",
        rider: "",
    });
    const inputEl = useRef(null);
    const [file, setFile] = useState([]);
    const [error, setError] = useState({
        name: "",
        displayType: "",
    });

    useEffect(() => {
        loadBasic()
        if (type === 'create') {
            setData({
                policyName: "",
                categoryId: "",
                companyId: "",
                icon: "",
                rider: "",
            })
        } else if (type === 'view') {
            if (!slug) {
                history.push('/company');
                return;
            }
            getDetails(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/company');
                return;
            }
            getDetails(slug);
        }
    }, [])

    const loadBasic = async () => {
        let companyParams = { term: slug };
        let res = await getCompanyList(companyParams);
        if (res.status === 'success') {
            setData({ ...data, company: res.data[0].companyName, companyId: res.data[0].id })
        } else {
            console.log(res)
        }

        let categoryParams = {};
        let resCategory = await getPolicyCategoryList(categoryParams);
        if (resCategory.status === 'success') {
            let cate = resCategory.data.map((c) => { return { ...c, label: c.name, value: c.id } })
            setPolicyCategoryOptions([...cate]);
        } else {
            console.log(resCategory);
        }
    }

    const getDetails = async (slug) => {
        let params = { id: slug };
        await getCompanyPolicyList(params).then((response) => {
            const result = response.data[0];
            setData({
                id: result.id,
                policyName: result.name,
                category: result.policy_category,
                company: result.company,
                icon: result.icon,
                rider: "",
            })
        }).catch((err) => {
            console.log(err);
            history.push('/company')
        })
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    const onFileChange = (event) => {
        setFile(event.target.files);
    };

    const submitForm = async () => {
        let check = true;
        let error = { name: "", displayType: "", };
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired")
        }
        if (!data.displayType) {
            check = false;
            error.displayType = translate(lang, "errorRequired")
        }
        setError({ ...error });
        if (check) {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("key", data.key);
            formData.append("dataDisplay", data.displayType);
            formData.append("level", data.level);
            if (file && file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formData.append("attachments", file[i], file[i].name);
                }
            }
            if (type === 'create') {
                await createMasterPolicy(formData).then((res) => {
                    console.log(res.status)
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/company');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type === 'edit') {
                let id = data.id;
                delete data.id;
                await editMasterPolicy(formData, id).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/company');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }


    return (
        <>
            <div className="card shadow col-12">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "detailsCompanyPolicy" : type === "edit" ? "editCompanyPolicy" : "createCompanyPolicy"))}
                    </h3>
                </div>
                <div className="card-body col-12 col-md-7 d-flex flex-column flex-wrap">
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'policyName')}</label>
                        <input type="text" name="policyName" value={data.policyName} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div>
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'policyCategory')}</label>
                        <Select
                            className="form-control"
                            value={policyCategoryOptions.filter(
                                (option) => option.value === data.categoryId
                            )}
                            isDisabled={type === 'view' ? true : false}
                            onChange={(e) => handleOptionSelected(e, "categoryId")}
                            options={policyCategoryOptions}
                            placeholder={translate(lang, "placeholderChoose")}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    height: "100%",
                                    border: 0,
                                    borderColor: "transparent",
                                    boxShadow: "none",
                                    "&:hover": {
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                    },
                                    backgroundColor: "transparent",
                                }),
                                valueContainer: (base, state) => ({
                                    ...base,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    borderColor: "transparent",
                                })
                            }}
                        />                    </div>
                    <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'company')}</label>
                        <input type="text" name="company" value={data.company} className="form-control" disabled readOnly placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div>
                    {/* <div className="form-group py-2">
                        <label className="form-label">{translate(lang, 'level')}</label>
                        <input type="number" min="0" step="1" value={data.level} onChange={(e) => { setData({ ...data, level: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                    </div> */}
                    <div className="py-2">
                        <div hidden={type === 'view' ? true : false}>
                            <label className="form-label">{translate(lang, 'icon')}</label>
                            <input type="file" className="form-control h-100" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} onChange={(e) => onFileChange(e)} ref={inputEl} />
                        </div>
                        {type !== 'create' ? (<div className="mt-2">
                            <label className="form-label">{translate(lang, 'currentIcon')}</label>
                            <div className={`icon-box`}>
                                <img src={`${api.policyMasterIcon}/${data.icon}`} alt="logo" className="w-100 h-100" />
                            </div>
                        </div>) : <></>}
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-sm btn-primary" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        <button type="button" className="btn btn-sm btn-dark mx-2" onClick={() => history.push('/company')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CompanyPolicyForm);