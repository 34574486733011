import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createMasterPolicy, createMemberPolicy, editMasterPolicy, editMemberPolicy, getCompanyList, getMasterPolicyList, getPolicyCategoryList, getRiderList, getUserList, getUserPolicyList, removeMemberPolicy,removePolicyDocument,uploadPolicyDocument } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { numberWithFormat, premiumTypeOptions, promptMessage, relationshipOptions, selectDropdownStyle } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import country from "../../../utils/country";
import Swal from "sweetalert2";
import lodash from "lodash"

const MemberPolicyForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const { type, slug } = useParams();
    const [userData, setUserData] = useState({
        fullName: "",
        gender: "",
        age: "",
        identityCard: 0,
        birthDate: "",
        email: "",
        phoneCode: "",
        phone: "",
        address: "",
    });
    const [freezeOriginalPolicyObject, setFreezeOriginalPolicyObject] = useState(null)
    const [agentList, setAgentList] = useState([]);
    const [currentPolicy, setCurrentPolicy] = useState("");
    const [policyList, setPolicyList] = useState([]);
    const [masterList, setMasterList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryDetailList, setCategoryDetailList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [policyData, setPolicyData] = useState({
        userId: type === 'create' ? slug : "",
        masterPolicyId: "",
        policyCategoryId: "",
        companyId: "",
        servicedBy: null,
        address:"",
        email:"",
        nominationList: [],
        totalSumAssured: 0,
        details: [],
        rider: [],
        // policyNumber: "",
        // policyName: "",
        // totalSumAssured: 0,
        // premiumAssured: 0,
        // premiumAmount: 0,
        // assuredName: "",
        // commencementDate: "",
        // premiumPaymentTerm: 0,
        // policyTerms: 0,
        // expiryDate: "",
    });
    // const [nominationList, setNominationList] = useState([
    //     // { name: "", identityCard: "", relationship: "", allocationPercentage: 0, phone: "", email: ""}
    // ])
    const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `${country[item].callingCode.length > 0 ? "+" + country[item].callingCode[0] : ""}` } }).filter((c) => c.value !== ""));
    const [relationOptions, setRelationOptions] = useState(relationshipOptions());
    const [premiumOptions, setPremiumOptions] = useState(premiumTypeOptions());

    const [riderOptions, setRiderOptions] = useState([]);
    const [assuredList, setAssuredList] = useState([]);
    const inputEl = useRef(null);
    const [file, setFile] = useState([]);
    const [error, setError] = useState({
        nominationList: [],
        details: [],
        assuredLists: [],
    });

    useEffect(() => {
        loadBasic();
        if (type === 'create') {
            setPolicyData({
                userId: type === 'create' ? slug : "",
                masterPolicyId: "",
                policyCategoryId: "",
                companyId: "",
                servicedBy: null,
                nominationList: [],
                details: [],
                rider: [],
            })
            getUserDetails(slug);
        } else if (type === 'view') {
            if (!slug) {
                history.push('/member');
                return;
            }
            getPolicyDetails(slug, true);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/member');
                return;
            }
            getPolicyDetails(slug, true);
        }
    }, [])

    useEffect(() => {
        //get rider list
        if (policyData.policyCategoryId) {
            getPolicyRiderList()
            if (type === 'create') {
                getPolicyCategoryDetails();
            }
        } else {
            if (type === 'create') {
                setPolicyData({ ...policyData, rider: [] })
            }
        }

    }, [policyData.policyCategoryId])

    useEffect(() => {
        if (currentPolicy) {
            getPolicyDetails(slug);
        }
    }, [currentPolicy])

    const loadBasic = async () => {
        let params = {};
        await getMasterPolicyList(params).then((response) => {
            let pcs = []
            const result = response.data.map(p => {
                pcs = [...pcs, ...p.policy_categories.map(c => ({ ...c, label: c.name, value: c.id }))];
                return { ...p, label: p.name, value: p.id }
            });
            setMasterList([...result]);
            setCategoryList([...pcs]);
        })
        let paramsCompany = {};
        await getCompanyList(paramsCompany).then((response) => {
            const result = response.data.map(p => { return { ...p, label: p.companyName, value: p.id } });
            setCompanyList([...result]);
        })
        getAgentList();
    }

    const getUserDetails = async (slug) => {
        let params = { id: slug };
        await getUserList(params).then((response) => {
            const result = response.data[0];
            setUserData({ ...result });
        }).catch((err) => {
            console.log(err);
            history.push('/member')
        })
    }

    const getAgentList = async () => {
        await getUserList({ isAgent: 1 }).then((response) => {
            const result = response.data.map(r => ({
                label: r.fullName,
                value: r.id,
            }));
            setAgentList([...result]);
        }).catch((err) => {
            console.log(err);
            history.push('/member')
        })
    }

    const getPolicyDetails = async (slug, basic = false) => {
        let params = { userId: slug };
        if (basic) { params.noJoin = 1; params.basic = 1 }
        else { params.id = currentPolicy }
        await getUserPolicyList(params).then((response) => {
            if (response.status === 'success') {
                let result = response.data;
                if (basic) {
                    let tempPolicy = response.data.map((dt) => {
                        let opt = dt.user_policy_category_details[0].value.find(d => ['policy_no'].includes(d.keyword));
                        return ({
                            label: opt.value,
                            value: dt.id,
                            required: ['additional'].includes(opt.value) ? false : true,
                        })
                    })
                    setPolicyList(tempPolicy);
                    // setPolicyData({ ...result, rider: result.policy_riders, nominationList: [], rider: [] });
                    setUserData({ ...result[0].customer });
                    if (currentPolicy) setCurrentPolicy(currentPolicy)
                }
                if (!basic) {
                    result = result[0];
                    // let tmp = result.find((policy) => policy.id === currentPolicy);
                    let policyAssureds = result.user_policy_assureds;
                    let policyCategory = result.policy_category.id;
                    let policyMaster = result.policy_category.policy_master.id;
                    let riders = result.user_policy_riders.map(r => ({ label: r.policy_rider.rider.name, value: r.policy_rider.rider.id, slug: r.policy_rider.rider.slug }))
                    let nominations = result.user_policy_nominations.map(n => ({ ...n, removed: false }));
                    let errorNomination = [...Array(nominations.length)].map(() => ({ fullName: "", identityCard: "", relationship: "", allocationPercentage: "", phoneCode: "", phone: "", email: "" }))
                    setPolicyData({ ...result, userId: result.customer.id, address:result?.address,email:result?.email , servicedBy: result.serviceAgent?.id, masterPolicyId: policyMaster, policyCategoryId: policyCategory, rider: [...riders], nominationList: nominations, details: result.user_policy_category_details[0].value, companyId: result.companyId })
                    setCategoryDetailList(result.user_policy_category_details[0].value);
                    setAssuredList([...policyAssureds.map(pa => ({ id: pa.id, categoryId: pa.policyCategoryId, value: pa.amount, removed: false }))])
                    // console.log(errorNomination, "here");
                    console.log("result.user_policy_category_details[0].value", result.user_policy_category_details[0].value)
                    let selectedUserPolicyCategoryDetails = JSON.parse(JSON.stringify(result.user_policy_category_details[0].value))
                    setFreezeOriginalPolicyObject(selectedUserPolicyCategoryDetails)
                    setError({
                        ...error,
                        details: [],
                        nominationList: [...errorNomination],
                        assuredLists: [],
                    })
                }
            } else {
                promptMessage(lang, 'error', response.message);
                history.push('/member');
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    // const getPolicyCategory = async () => {
    //     if (type === 'create') {
    //         setPolicyData({ ...policyData, rider: [] })
    //     }
    //     let params = { policyCategoryId: policyData.policyCategoryId }
    //     let res = await getRiderList(params);
    //     if (res.status === 'success') {
    //         let data = res.data.map(r => ({ label: r.name, value: r.id, slug: r.slug }));
    //         setRiderOptions([...data]);
    //     } else {
    //         setRiderOptions([])
    //     }
    // }

    const getPolicyRiderList = async () => {
        if (type === 'create') {
            setPolicyData({ ...policyData, rider: [] })
        }
        let params = { policyCategoryId: policyData.policyCategoryId }
        let res = await getRiderList(params);
        if (res.status === 'success') {
            let data = res.data.map(r => ({ label: r.name, value: r.id, slug: r.slug }));
            setRiderOptions([...data]);
        } else {
            setRiderOptions([])
        }
    }

    const getPolicyCategoryDetails = async () => {
        let params = { id: policyData.policyCategoryId, policyMasterId:policyData.masterPolicyId }
        let res = await getPolicyCategoryList(params);
        if (res.status === 'success') {
            let data = res.data[0].policy_category_details.map(r => ({ label: r.label, type: r.type, value: '', keyword: r.keyword, required: ['additional'].includes(r.keyword.toLowerCase()) ? false : true, isAddOn: r.isAddOn }));
            setCategoryDetailList([...data]);
            setAssuredList([{ categoryId: policyData.policyCategoryId, value: 0, removed: false }])
            let tempError = error;
            tempError.details = data.map((d) => ({ [d.keyword]: "" }));
            tempError.assuredLists = [""];
            setError({ ...tempError })
            // setRiderOptions([...data]);
        } else {
            // setRiderOptions([])
        }
    }

    const addNewAssuredAmount = () => {
        let temp = assuredList;
        temp.push({ categoryId: policyData.policyCategoryId, value: 0, removed: false })
        setAssuredList([...temp]);
        setError({ ...error, assuredLists: [...error.assuredLists, ""] })
    }

    const removeAssuredAmount = (index) => {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let temp = assuredList;
                temp[index].removed = true;
                setAssuredList([...temp]);
            }
        })
    }

    const addNewNomination = () => {
        let temp = policyData;
        temp.nominationList.push({ fullName: "", identityCard: "", relationship: "", allocationPercentage: 0, phoneCode: "", phone: "", email: "" })
        let tempErr = error;
        tempErr.nominationList.push({ fullName: "", identityCard: "", relationship: "", allocationPercentage: "", phoneCode: "", phone: "", email: "" })
        setError({ ...tempErr });
        setPolicyData({ ...temp });
    }

    const removeNomination = (index) => {
        let temp = policyData;
        temp.nominationList[index].removed = true;
        let tempErr = error;
        tempErr.nominationList.splice(index, 1);
        setError({ ...tempErr });
        // temp.nominationList.splice(index, 1)
        setPolicyData({ ...temp });
    }

    const handleUserPolicyBasicInfo = (e, name) =>{
        let list = { ...policyData };
        list[name]  =e.target.value
        setPolicyData({ ...list });
    }
    const handleOptionSelected = (e, name, index = -1) => {
        if (type === 'view') return;
        let list = { ...policyData };
        if (name === 'masterPolicyId') {
            let master = masterList.find(m => m.value === e.value);
            master.policy_categories = master.policy_categories.map(c => { return { ...c, label: c.name, value: c.id } })
            setCategoryList([...master.policy_categories]);
            list["policyCategoryId"] = master.policy_categories.find(pc => pc.id === policyData.policyCategoryId) ? policyData.policyCategoryId : "";
            list[name] = e.value;
        }
        else if (name === 'rider') {
            if (type === 'edit') return;
            list.rider = e ?? [];
        }
        else if (name === 'sum_assured_category') {
            let temp = assuredList;
            temp[index].categoryId = e.value;
            setAssuredList([...temp]);
            return
        } else if (name == 'premium_type') {
            const temp = categoryDetailList;
            temp[index].value = e.value;
            setCategoryDetailList([...temp]);
            return
        }
        else if (index > -1) {
            list.nominationList[index][name] = e.value;
        }
        else {
            list[name] = e.value;
        }
        setPolicyData({ ...list });
    }

    const onInputChange = (event, index = -1, listName) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = { ...policyData };
        if (listName === 'assuredList') {
            let temp = assuredList;
            temp[index].value = value;
            setAssuredList([...temp])
            return;
        } else if (index > -1) {
            temp.nominationList[index][name] = value;
        } else {
            temp[name] = value;
        }
        setPolicyData({ ...temp });
    };

    const detailsInputChanges = (e, item, iIndex) => {
        if (type === 'view') return;
        const temp = categoryDetailList;
        temp[iIndex].value = e.target.value;
        setCategoryDetailList([...temp]);
    }

    const onFileChange = (event) => {
        if (type === 'view') return;
        setFile(event.target.files);
    };

    const submitForm = async () => {
        Swal.fire({
            title: translate(props.lang, "updateConfirmation"),
            text: translate(props.lang, "updateMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let check = true;
                promptMessage(lang, 'info', translate(lang, 'validateForm'), {
                    timer: 1000,
                    didOpen: () => {
                        Swal.showLoading()
                        let err = {
                            address:"",
                            email:"",
                            masterPolicyId: "",
                            policyCategoryId: "",
                            companyId: "",
                            servicedBy: "",
                            nominationList: [...error.nominationList],
                            details: [...error.details],
                            assuredLists: [...error.assuredLists],
                        };
                        console.log("policy_data",policyData);
                        [...Object.keys(err)].forEach((key) => {
                            if (['nominationList', 'assuredLists', 'details'].includes(key)) {
                                return;
                            }
                            if (type === 'edit' && ['pin', 'confirmPin'].includes(key)) {
                                // skip not showing data
                                return;
                            }
                            else if (policyData[key] === "" || policyData[key] === undefined || policyData[key] === null) {
                                check = false;
                                err[key] = translate(lang, "errorRequired")
                            }
                        })
                        let temp = policyData;
                        let totalNominationPercentage = 0;
                        let totalP = temp.nominationList.reduce((t, i) => t + parseFloat(i.allocationPercentage), totalNominationPercentage);
                        if (totalP > 100) {
                            check = false;
                            promptMessage(lang, 'error', translate(lang, "nominationPercentageOverLimit"))
                        }
                        if (!currentPolicy && type !== 'create') {
                            check = false;
                            promptMessage(lang, 'error', translate(lang, 'noPolicySelected'))
                        };
                        //check details
                        if (categoryDetailList.length > 0) {
                            // console.log(categoryDetailList);
                            categoryDetailList.forEach((nm, i) => {
                                if (nm.required) {
                                    if (nm.keyword.toLowerCase().indexOf('sum_assured') > -1 || nm.keyword.toLowerCase().indexOf('sum_insured') > -1 || nm.keyword.toLowerCase().indexOf('vehicle_sum_insured') > -1) {
                                        if (assuredList.length > 0) {
                                            assuredList.forEach((al, iAL) => {
                                                console.log(al);
                                                if (al.removed) return;
                                                if (al.value === "" || al.value === undefined || al.value === null) {
                                                    check = false;
                                                    console.log(err, iAL);
                                                    err.assuredLists[iAL] = translate(lang, "errorRequired")
                                                }
                                            })
                                        }
                                        return;
                                    }
                                    if (nm.value === "" || nm.value === undefined || nm.value === null) {
                                        check = false;
                                        err.details[nm.keyword] = translate(lang, "errorRequired")
                                    }
                                }
                            })
                        }
                        //check nomination
                        if (temp.nominationList.length > 0) {
                            temp.nominationList.forEach((nm, i) => {
                                [...Object.keys(nm)].forEach((key) => {
                                    if (policyData.nominationList[i][key] === "" || policyData.nominationList[i][key] === undefined || policyData.nominationList[i][key] === null) {
                                        check = false;
                                        err.nominationList[i][key] = translate(lang, "errorRequired")
                                    } else {
                                        err.nominationList[i][key] = "";
                                    }
                                })
                            })
                        }
                        console.log(err);
                        setError({ ...err });
                        return check;
                    },
                    didDestroy: () => {
                        Swal.hideLoading()
                    },
                }).then(async (result) => {
                    if (check) {
                        let payload = {
                            userId: policyData.userId,
                            address: policyData.address,
                            email: policyData.email,
                            masterPolicyId: policyData.masterPolicyId,
                            policyCategoryId: policyData.policyCategoryId,
                            companyId: policyData.companyId,
                            totalSumAssured: assuredList.reduce((acc, item) => acc += parseFloat(item.value) && !item.removed ? parseFloat(item.value) : 0, 0),
                            nominationList: policyData.nominationList,
                            assuredList: assuredList,
                            rider: policyData.rider,
                            serviceBy: policyData.servicedBy,
                            details: categoryDetailList,
                        }
                        console.log("payload",payload)
                    
                        if (type === 'create') {
                            // let tempCategoryDetailList = payload.details
                            // let userPolicyDocumentsId = []
                            // for (const item of tempCategoryDetailList) {
                            //     if (item.type == "file"){
                            //         let formData = new FormData();
                            //         formData.append("policy_category_id", payload.policyCategoryId);
                            //         formData.append("user_policy_id", 0);

                            //         if (file && file.length > 0) {
                            //             for (let i = 0; i < file.length; i++) {
                            //                 formData.append("attachments", file[i], file[i].name);
                            //             }
                            //         }
                            //         const uploadFileResult =await uploadPolicyDocument(payload.userId, formData)
                            //         const documentInfo = JSON.parse(uploadFileResult.data.document)
                            //         item.value = documentInfo[0].s
                            //         //payload.userPolicyDocuments = uploadFileResult.
                            //         userPolicyDocumentsId.push(uploadFileResult.data.id)
                            //     }
                            // }
                            // if (userPolicyDocumentsId.length>0){
                            //     payload.userPolicyDocuments = userPolicyDocumentsId
                            // }
                            //payload.details = tempCategoryDetailList


                            await createMemberPolicy(payload).then(async (res) => {
                                console.log(res.status)
                                if (res.status === 'success') {
                                    let tempCategoryDetailList = payload.details
                                    for (const item of tempCategoryDetailList) {
                                        if (item.type == "file"){
                                            let formData = new FormData();
                                            formData.append("policy_category_id", payload.policyCategoryId);
                                            formData.append("user_policy_id", res.data.userPolicyId);
                                            formData.append("keyword", item.keyword);

                                            if (file && file.length > 0) {
                                                for (let i = 0; i < file.length; i++) {
                                                    formData.append("attachments", file[i], file[i].name);
                                                }
                                            }
                                            const uploadFileResult =await uploadPolicyDocument(payload.userId, formData)
                                            const documentInfo = JSON.parse(uploadFileResult.data.document)
                                            item.value = documentInfo[0].s
                                            //payload.userPolicyDocuments = uploadFileResult.
                                        }
                                    }
                                    await promptMessage(lang, 'success', res.message)
                                    await history.push('/member');
                                } else {
                                    promptMessage(lang, 'error', res.message)
                                }
                            }).catch((error) => {
                                promptMessage(lang, 'error', error);
                            })
                        } else if (type === 'edit') {
                            
                            await editMemberPolicy(payload, policyData.id).then(async(res) => {
                                if (res.status === 'success') {
                                    let tempCategoryDetailList = payload.details
                                    for (const [index, item] of tempCategoryDetailList.entries()) {

                                        if (item.type === "file" && file) {

                                            let targetRemoveFileValue = freezeOriginalPolicyObject[index].value.substring(0, freezeOriginalPolicyObject[index].value.lastIndexOf('.'))
                                            removePolicyDocument(payload.userId, targetRemoveFileValue);

                                            let formData = new FormData();
                                            formData.append("policy_category_id", payload.policyCategoryId);
                                            formData.append("user_policy_id", policyData.id);
                                            formData.append("keyword", item.keyword);

                                            if (file && file.length > 0) {
                                                for (let i = 0; i < file.length; i++) {
                                                    formData.append("attachments", file[i], file[i].name);
                                                }
                                            }
                                            const uploadFileResult = await uploadPolicyDocument(payload.userId, formData);
                                            const documentInfo = JSON.parse(uploadFileResult.data.document);
                                            item.value = documentInfo[0].s;
                                        }
                                    };
                                    await promptMessage(lang, 'success', res.message)
                                    await history.push('/member');
                                    //getPolicyDetails(slug);
                                } else {
                                    promptMessage(lang, 'error', res.message)
                                }
                            }).catch((error) => {
                                promptMessage(lang, 'error', error);
                            })
                        }
                    } else {
                        promptMessage(lang, 'error', translate(lang, 'formErrorPleaseCheck'));
                    }
                })
            }
        })
    }

    const removePolicy = async () => {
        if (!currentPolicy) {
            promptMessage(lang, 'error', translate(lang, 'noPolicySelected'))
            return;
        };
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeMemberPolicy(currentPolicy);
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    history.push('/member');

                    // getPolicyDetails(slug);
                    // setPolicyData({
                    //     userId: type === 'create' ? slug : "",
                    //     masterPolicyId: "",
                    //     policyCategoryId: "",
                    //     companyId: "",
                    //     servicedBy: null,
                    //     nominationList: [],
                    //     details: [],
                    // })
                } else {
                    promptMessage(lang, 'error', res.message)
                    getPolicyDetails(slug);
                }
            }
        })
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="col-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "detailsMemberPolicy" : type === "edit" ? "editMemberPolicy" : "createMemberPolicy"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column flex-md-column flex-xl-row gap-2">
                            <div className="col-12 col-xl-6 d-flex flex-column">
                                <div className="col-12 d-flex flex-row flex-wrap mb-1">
                                    {type === 'edit' ? <div className="form-group col-12 px-2">
                                        <label className="form-label">{translate(lang, 'memberPolicy')}</label>
                                        <Select
                                            className="form-control"
                                            value={policyList.filter(
                                                (option) => option.value === currentPolicy
                                            )}
                                            isDisabled={type !== 'edit' ? true : false}
                                            onChange={(e) => setCurrentPolicy(e.value)}
                                            options={policyList}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{ ...selectDropdownStyle() }}
                                        />
                                    </div> : <></>}
                                    <div className="form-group col-12 px-2">
                                        <label className="form-label required">{translate(lang, 'fullName')}</label>
                                        <input type="text" value={userData.fullName} className="form-control" disabled placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    </div>
                                    <div className="form-group col-12 col-md-6 px-2">
                                        <label className="form-label required">{translate(lang, 'contactNumber')}</label>
                                        <input type="text" value={userData.phoneCode + userData.phone} className="form-control" disabled placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    </div>
                                    <div className="form-group col-12 col-md-6 px-2">
                                        <label className="form-label required">{translate(lang, 'email')}</label>
                                        <input type="text" value={policyData.email} onChange={(e) => handleUserPolicyBasicInfo(e, 'email')} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.email == "" ? <></> : <p className="text-danger">{error.email}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-12 px-2">
                                        <label className="form-label required">{translate(lang, 'address')}</label>
                                        <input type="text" value={policyData.address} onChange={(e) => handleUserPolicyBasicInfo(e, 'address')} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.address == "" ? <></> : <p className="text-danger">{error.address}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 px-2">
                                        <label className="form-label required">{translate(lang, 'company')}</label>
                                        <Select
                                            className="form-control h-100"
                                            value={companyList.filter(
                                                (option) => option.value === policyData.companyId
                                            )}
                                            isDisabled={type === 'view' ? true : false}
                                            onChange={(e) => handleOptionSelected(e, "companyId")}
                                            options={companyList}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{ ...selectDropdownStyle() }}
                                        />
                                        {error.companyId == "" ? <></> : <p className="text-danger">{error.companyId}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 px-2">
                                        <label className="form-label required">{translate(lang, 'servicedBy')}</label>
                                        <Select
                                            className="form-control"
                                            value={agentList.filter(
                                                (option) => option.value === policyData.servicedBy
                                            )}
                                            isDisabled={type === 'view' ? true : false}
                                            onChange={(e) => handleOptionSelected(e, "servicedBy")}
                                            options={agentList}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{ ...selectDropdownStyle() }}
                                        />
                                        {error.servicedBy == "" ? <></> : <p className="text-danger">{error.servicedBy}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 px-2">
                                        <label className="form-label required">{translate(lang, 'masterPolicy')}</label>
                                        {type !== 'create' ? <input type="text" value={masterList.length > 0 && masterList.find(m => m.id === policyData.masterPolicyId) ? masterList.find(m => m.id === policyData.masterPolicyId).label : ""} className="form-control" disabled placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            : <Select
                                                className="form-control"
                                                value={masterList.filter(
                                                    (option) => option.value === policyData.masterPolicyId
                                                )}
                                                isDisabled={type === 'view' ? true : false}
                                                onChange={(e) => handleOptionSelected(e, "masterPolicyId")}
                                                options={masterList}
                                                placeholder={translate(lang, "placeholderChoose")}
                                                styles={{ ...selectDropdownStyle() }}
                                            />}
                                        {error.masterPolicyId == "" ? <></> : <p className="text-danger">{error.masterPolicyId}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 px-2">
                                        <label className="form-label required">{translate(lang, 'policyCategory')}</label>
                                        {type !== 'create' ? <input type="text" value={categoryList.length > 0 && categoryList.find(c => c.id === policyData.policyCategoryId) ? categoryList.find(c => c.id === policyData.policyCategoryId).label : ""} className="form-control" disabled placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            : <Select
                                                className="form-control"
                                                value={categoryList.filter(
                                                    (option) => option.value === policyData.policyCategoryId
                                                )}
                                                isDisabled={type === 'view' ? true : false}
                                                onChange={(e) => handleOptionSelected(e, "policyCategoryId")}
                                                options={categoryList.filter(c => c.policyMasterId === policyData.masterPolicyId)}
                                                placeholder={translate(lang, "placeholderChoose")}
                                                styles={{ ...selectDropdownStyle() }}
                                            />}
                                        {error.policyCategoryId == "" ? <></> : <p className="text-danger">{error.policyCategoryId}</p>}
                                    </div>
                                    {/* <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'policyNumber')}</label>
                                        <input type="text" value={policyData.policyNumber} name="policyNumber" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.policyNumber == "" ? <></> : <p className="text-danger">{error.policyNumber}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'policyName')}</label>
                                        <input type="text" value={policyData.policyName} name="policyName" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.policyName == "" ? <></> : <p className="text-danger">{error.policyName}</p>}
                                    </div> */}
                                    <div className="form-group col-12 py-2 px-2">
                                        <label className="form-label">{translate(lang, 'policyRider')}</label>
                                        <Select
                                            isMulti
                                            className="form-control"
                                            value={policyData.rider}
                                            isDisabled={type !== 'create' ? true : false}
                                            onChange={(e) => handleOptionSelected(e, "rider")}
                                            options={riderOptions}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{
                                                ...selectDropdownStyle(),
                                                menu: (base, state) => ({
                                                    ...base,
                                                    width: "90%",
                                                })
                                            }}
                                        />

                                    </div>
                    
                                </div>
                                <hr></hr>
                                {categoryDetailList.map((detail, dIndex) => {
                                    return (
                                        <div key={detail.keyword+dIndex}>
                                            {detail.keyword == "premium_type" ? 
                                                <div className="form-group mb-3 px-2">
                                                    <label className={`form-label ${detail?.required ? 'required' : ''}`}>{detail?.label}</label>
                                                    <div key={"premium_type"} className="input-group mb-0">
                                                        <Select
                                                            className="form-control mb-0"
                                                            value={premiumOptions.filter(
                                                                (option) => option.value === categoryDetailList[dIndex].value
                                                            )}
                                                            isDisabled={false}
                                                            onChange={(e) => handleOptionSelected(e, "premium_type",dIndex)}
                                                            options={premiumOptions}
                                                            placeholder={translate(lang, "placeholderChoose")}
                                                            styles={{ ...selectDropdownStyle() }}
                                                        />
                                                    <label className="text-danger">{error.premium_type}</label>
                                                </div>
                                              
                                            </div> :
                                            detail.isAddOn &&
                                                ['sum_assured', 'sum_insured'].findIndex(txt => detail.keyword.toLowerCase().includes(txt)) > -1 ? (
                                                <div className="form-group px-2">
                                                    <div className="d-flex col-12 justify-content-around">
                                                        <span>
                                                            <label className="form-label required">{detail?.label}</label>
                                                            <button className="btn btn-sm btn-primary add-on-icon rounded-2 ms-3" onClick={() => addNewAssuredAmount()}>+</button>
                                                        </span>
                                                        <label className="ms-auto">Total Sum Assured: {numberWithFormat(assuredList.reduce((acc, item) => acc += parseFloat(item.value) && !item.removed ? parseFloat(item.value) : 0, 0))}</label>
                                                    </div>
                                                    {assuredList.map((assured, aIndex) => {
                                                        return !assured.removed ? (
                                                            <div>
                                                                <div key={"assured_"+aIndex} className="input-group mb-0">
                                                                    <Select
                                                                        className="form-control mb-0"
                                                                        value={categoryList.filter(
                                                                            (option) => option.value === assured.categoryId
                                                                        )}
                                                                        isDisabled={type !== 'create' && assured.id ? true : false}
                                                                        onChange={(e) => handleOptionSelected(e, "sum_assured_category", aIndex)}
                                                                        options={categoryList.filter(c => c.policyMasterId === policyData.masterPolicyId)}
                                                                        placeholder={translate(lang, "placeholderChoose")}
                                                                        styles={{ ...selectDropdownStyle() }}
                                                                    />
                                                                    <input className="form-control mb-0 input-form assured" type={detail?.type} value={assured?.value} onChange={(e) => onInputChange(e, aIndex, 'assuredList')} min={0} onWheel={(e) => e.target.blur()} />
                                                                    <button className="btn btn-outline-danger  py-0 remove-assured-button" type="button" onClick={() => removeAssuredAmount(aIndex)} disabled={assuredList.filter(a => a.removed === false).length === 1 ? true : false}><i className="fas fa-trash"></i></button>
                                                                </div>
                                                                <label className="text-danger">{error.assuredLists[aIndex]}</label>
                                                            </div>
                                                        ) : <></>
                                                    })}
                                                </div>)
                                                : detail?.type !=  "file" ?(<div className="form-group mb-1 px-2">
                                                    <label className={`form-label ${detail?.required ? 'required' : ''}`}>{detail?.label}</label>
                                                    <input className="form-control mb-0 " type={detail?.type} value={detail?.value} onChange={(e) => detailsInputChanges(e, detail, dIndex)} min={0} onWheel={(e) => e.target.blur()} />
                                                    <label className="text-danger">{error.details[detail?.keyword]}</label>
                                                </div>):<><div className="form-group mb-1 px-2">
                                                    <label className={`form-label ${detail?.required ? 'required' : ''}`}>{detail?.label}</label>
                                                        {['edit'].includes(type) ? <div className="form-group mb-1">
                                                            <label className={`form-label`}>{translate(lang, 'currentReferenceDocument')+" "}:
                                                                <a target="_blank" href={`${api.userPolicyDocument}/${policyData.userId}/${detail.value}`}>{`${detail.fileName}`}</a>
                                                            </label>
                                                        </div> : <></>
                                                        }
                                                        <input className="form-control mb-0 " type={detail?.type} onChange={(e) => { 
                                                            detailsInputChanges(e, detail, dIndex); 
                                                            onFileChange(e); }} min={0} onWheel={(e) => e.target.blur()} />
                                                        <label className="text-danger">{error.details[detail?.keyword]}</label>
                                                    </div>
                                                     
                                                        
                                                {/*                                          
                                                <div className="mt-2 d-flex flex-column">
                                                    <label className="form-label required">{translate(lang, 'currentCompanLogo')}</label>
                                                    <img src={`${api.companyLogo}/${detail?.value}`} alt="image" className="img-thumbnail icon-box" />
                                                </div> */}
                                                </>
                                            }
                                        </div>
                                    )
                                })}
                                {/* <div className="col-12 d-flex flex-row flex-wrap">
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'assuredName')}</label>
                                        <input type="text" value={policyData.assuredName} name="assuredName" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.assuredName == "" ? <></> : <p className="text-danger">{error.assuredName}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'totalSumAssured')}</label>
                                        <input type="number" value={policyData.totalSumAssured} name="totalSumAssured" onChange={(e) => onInputChange(e)} onWheel={(e) => e.target.blur()} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.totalSumAssured == "" ? <></> : <p className="text-danger">{error.totalSumAssured}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'premiumAmount')}</label>
                                        <input type="number" value={policyData.premiumAmount} name="premiumAmount" onChange={(e) => onInputChange(e)} onWheel={(e) => e.target.blur()} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.premiumAmount == "" ? <></> : <p className="text-danger">{error.premiumAmount}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'commencementDate')}</label>
                                        <input type="date" value={policyData.commencementDate} name="commencementDate" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.commencementDate == "" ? <></> : <p className="text-danger">{error.commencementDate}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'premiumPaymentTerm')}</label>
                                        <input type="number" value={policyData.premiumPaymentTerm} name="premiumPaymentTerm" onChange={(e) => onInputChange(e)} onWheel={(e) => e.target.blur()} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.premiumPaymentTerm == "" ? <></> : <p className="text-danger">{error.premiumPaymentTerm}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'policyTerms')}</label>
                                        <input type="number" value={policyData.policyTerms} name="policyTerms" onChange={(e) => onInputChange(e)} onWheel={(e) => e.target.blur()} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.policyTerms == "" ? <></> : <p className="text-danger">{error.policyTerms}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'expireDate')}</label>
                                        <input type="date" value={policyData.expiryDate} name="expiryDate" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.expiryDate == "" ? <></> : <p className="text-danger">{error.expiryDate}</p>}
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-12 col-xl-6 d-flex flex-column flex-grow-1 nomination-container">
                                <div className="px-3 d-flex flex-row mb-3">
                                    <span className="h3 my-auto">{translate(lang, "nominationList")}</span>
                                    {['create', 'edit'].includes(type) ? <span className="ms-auto"><button className="btn btn-sm btn-primary" type="button" onClick={() => addNewNomination()}>Add Nomination</button></span> : <></>}
                                </div>
                                <div className="col-12 d-flex flex-row flex-wrap px-2">
                                    <div className="d-flex flex-column overflow-auto p-2 w-100 nomination-list-container">
                                        {policyData.nominationList && policyData.nominationList.length < 1 ? (<><p className="ms-3 mt-2">{translate(lang, "noNominationAssigned")}</p></>) :
                                            policyData.nominationList.map((n, i) => n.removed ? <></> : (
                                                <div key={i} className="card rounded-3 shadow h-auto p-1 d-flex flex-column flex-wrap">
                                                    {['create', 'edit'].includes(type) ? <div className="card-header px-2 py-2 d-flex justify-content-end">
                                                        <button type="button" className="btn btn-sm btn-warning text-black" onClick={() => removeNomination(i)}>Remove</button>
                                                    </div> : <></>}
                                                    <div className="card-body p-0">
                                                        <div className="d-flex flex-column flex-md-row flex-wrap">
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'fullName')}</label>
                                                                <input type="text" value={n.fullName} name="fullName" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {error.nominationList.length > 1 ? <></> : error.nominationList[i]?.fullName == "" ? <></> : <p className="text-danger">{error.nominationList[i]?.fullName}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'identityCard')}</label>
                                                                <input type="text" value={n.identityCard} name="identityCard" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {error.nominationList.length > 1 ? <></> : error.nominationList[i]?.identityCard == "" ? <></> : <p className="text-danger">{error.nominationList[i]?.identityCard}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'phoneCode')}</label>
                                                                <Select
                                                                    className="form-control"
                                                                    value={phoneCodeOptions.filter((option) => {
                                                                        return option.value === n.phoneCode
                                                                    }
                                                                    )}
                                                                    menuPlacement={i > 0 && i === policyData.nominationList.length - 1 ? "top" : "auto"}
                                                                    isDisabled={type === 'view' ? true : false}
                                                                    onChange={(e) => handleOptionSelected(e, "phoneCode", i)}
                                                                    options={phoneCodeOptions}
                                                                    placeholder={translate(lang, "placeholderChoose")}
                                                                    styles={selectDropdownStyle()}
                                                                />
                                                                {error.nominationList.length > 1 ? <></> : error.nominationList[i]?.phoneCode == "" ? <></> : <p className="text-danger">{error.nominationList[i]?.phoneCode}</p>}
                                                                {/* <input type="text" value={n.phoneCode} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'phone')}</label>
                                                                <input type="text" value={n.phone} name="phone" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {error.nominationList.length > 1 ? <></> : error.nominationList[i]?.phone == "" ? <></> : <p className="text-danger">{error.nominationList[i]?.phone}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'email')}</label>
                                                                <input type="text" value={n.email} name="email" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {error.nominationList.length > 1 ? <></> : error.nominationList[i]?.email == "" ? <></> : <p className="text-danger">{error.nominationList[i]?.email}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'relationship')}</label>
                                                                {/* <input type="text" value={n.relationship} name="relationship" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                                                <Select
                                                                    className="form-control"
                                                                    value={relationOptions.filter((option) => {
                                                                        return option.value === n.relationship
                                                                    })}
                                                                    menuPlacement={i > 0 && i === policyData.nominationList.length - 1 ? "top" : "auto"}
                                                                    isDisabled={type === 'view' ? true : false}
                                                                    onChange={(e) => handleOptionSelected(e, "relationship", i)}
                                                                    options={relationOptions}
                                                                    placeholder={translate(lang, "placeholderChoose")}
                                                                    styles={{
                                                                        ...selectDropdownStyle(),
                                                                        menu: (base, state) => ({
                                                                            ...base,
                                                                        }),
                                                                        menuList: (base, state) => ({
                                                                            ...base,
                                                                            maxHeight: "20vh"
                                                                        })
                                                                    }}
                                                                />
                                                                {error.nominationList.length > 1 ? <></> : error.nominationList[i]?.relationship == "" ? <></> : <p className="text-danger">{error.nominationList[i]?.relationship}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'allocationPercentage')}</label>
                                                                <input type="number" min={0} step={0.01} value={n.allocationPercentage} name="allocationPercentage" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row gap-3">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                                <button type="button" className="btn btn-dark btn-sm" onClick={() => history.push('/member')}>{translate(lang, "back")}</button>
                                <button type="button" hidden={type === 'edit' ? false : true} className="btn btn-danger btn-sm ms-auto" onClick={() => removePolicy()}>{translate(lang, "delete")}</button>
                                {/* <button type="button" hidden={type === 'create' ? true : false} className="btn btn-dark btn-sm ms-2 me-2" onClick={() => history.push('/member')}>{translate(lang, "delete")}</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(MemberPolicyForm);