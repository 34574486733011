import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getRiderList, removeRider } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";

const RiderSettings = (props) => {
    const lang = props.lang;
    const tableColumns = [
        {
            Header: translate(lang, 'index'),
            accessor: (row, index) => {
                return index + 1;
            }
        },
        {
            Header: translate(lang, 'name'),
            accessor: (row, index) => {
                return row.name;
            }
        },
        {
            Header: translate(lang, 'code'),
            accessor: (row, index) => {
                return row.key;
            }
        },
        {
            Header: translate(lang, 'assignedPolicyCategory'),
            accessor: (row, index) => {
                return <><div className="d-flex flex-row flex-wrap">{row.policy_riders.map((r) => (<span className="badge badge-primary me-1">{r.policy_category.name}</span>))}</div></>;
            }
        },
        {
            Header: translate(lang, 'action'),
            accessor: (row, index) => {
                return (<>
                    <div className="row">
                        <Link
                            to={`/settings/rider/view/${row.slug}`}
                            className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                            <i className="fas fa-eye"></i>
                        </Link>
                        <Link
                            to={`/settings/rider/edit/${row.slug}`}
                            className="btn btn-warning shadow btn-xs sharp me-1"
                        >
                            <i className="fas fa-pen"></i>
                        </Link>
                        <span
                            onClick={() => removeItem(row.id)}
                            className="btn btn-danger shadow btn-xs sharp"
                        >
                            <i className="fa fa-trash"></i>
                        </span>
                    </div>
                </>)
            }
        },
    ]
    const [data, setData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "rider_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        let query = {};
        let res = await getRiderList(query);
        if (res?.status === 'success') {
            setData([...res.data])
            setFileData([...res.data])
        } else {
        }
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang,"delete"),
            text: translate(props.lang,"deleteMessage"),
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            dangerMode: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeRider(id);
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                    getList();
                }
            } 
        })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div>
                        <Link to="/settings/rider/create" className="btn btn-primary btn-sm">{translate(lang, 'createRider')}</Link>
                    </div>
                </div>
                <div className="card-body">
                    <div id="job_data" className="dataTables_wrapper">
                        <CustomTable
                            lang={props.lang}
                            columns={tableColumns}
                            data={data}
                            excelExport={false}
                            fileData={fileData}
                        ></CustomTable>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(RiderSettings);