import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createMasterPolicy, editMasterPolicy, getCompanyList, getCompanyPolicyList, getMasterPolicyList, getPolicyCategoryList, removeCompanyPolicy, updatePolicyQuestionnaire } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { dataDisplayOptions, promptMessage, questionairType } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import Swal from "sweetalert2";
import CustomTable from "../../components/CustomTable";
import ListView from "../../components/ListView";
import { UncontrolledCollapse } from "reactstrap";

const QuestionairForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const [policyCategory, setPolicyCategory] = useState({});
    const { type, slug } = useParams();
    const [data, setData] = useState([{
        questionTitle: "",
        questionDescription: "",
        questionType: "",
        questionOptions: [{ answer: "", }],
    }]);
    const [questionairTypeOptions, setQuestionairTypeOptions] = useState(questionairType);
    const [error, setError] = useState([{
        questionTitle: "",
        questionDescription: "",
        questionType: "",
        questionOptions: "",
    }]);

    useEffect(() => {
        if (type === 'create') {
            setData([{
                questionTitle: "",
                questionDescription: "",
                questionType: "",
                questionOptions: [{ answer: "", }],
            }])
        } else if (type === 'view') {
            if (!slug) {
                history.push('/questionair');
                return;
            }
            getDetails(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/questionair');
                return;
            }
            getDetails(slug);
        }
    }, [])

    useEffect(() => {
        // console.log(data);
    }, [data])

    const getDetails = async (slug) => {
        let params = { slug: slug };
        await getPolicyCategoryList(params).then((response) => {
            const result = response.data[0];
            setPolicyCategory(result);
            setData([...result.questionairs.map((q) => {
                if (q.questions !== "") {
                    let qJson = JSON.parse(q.questions);
                    if (qJson && qJson.length > 0) {
                        return {
                            questionTitle: qJson[0].questionTitle,
                            questionDescription: qJson[0].questionDescription,
                            questionType: qJson[0].questionType,
                            questionOptions: qJson[0].questionOptions,
                        }
                    }
                } else {
                    return {
                        questionTitle: "",
                        questionDescription: "",
                        questionType: "",
                        questionOptions: "",
                    }
                }
            })])
        }).catch((err) => {
            console.log(err);
            history.push('/questionair')
        })
    }

    const addNewQuestion = () => {
        let temp = data;
        temp.push({
            questionTitle: "",
            questionType: "",
            questionOptions: [{
                answer: "",
            }],
        });
        setData([...temp])
    }

    const removeQuestion = (i) => {
        let temp = data;
        temp.splice(i, 1);
        setData([...temp])
    }

    const addNewAnswerOption = (i) => {
        let temp = data;
        temp[i].questionOptions.push({
            answer: "",
        });
        setData([...temp])
    }

    const removeAnswerOption = (row, i) => {
        let temp = data;
        temp[row].questionOptions.splice(i, 1);
        setData([...temp])
    }

    const moveUpQuestion = (i) => {
        if (i === 0) return;
        let temp = [...data];
        let newIndex = i - 1;
        let moved = temp.splice(i, 1);
        temp.splice(newIndex, 0, moved[0]);
        setData([...temp]);
    }

    const moveDownQuestion = (i) => {
        if (i === data.length - 1) return;
        let temp = [...data];
        let newIndex = i + 1;
        let moved = temp.splice(i, 1);
        temp.splice(newIndex, 0, moved[0]);
        setData([...temp]);
    }

    const handleOptionSelected = (e, name, i) => {
        const { value } = e;
        const list = [...data];
        list[i][name] = value;
        if (value === 'fillIn') {
            list[i]["questionOptions"] = [];
        } else {
            if (list[i]["questionOptions"].length < 1) list[i]["questionOptions"] = [{ answer: "" }];
        }
        setData([...list]);
    }

    const onInputChange = (event, index, opt = null) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        if (opt && opt.name === "answerOption") {
            temp[index][name][opt.no].answer = value;
        } else {
            temp[index][name] = value;
        }
        setData([...temp]);
    };

    const submitForm = async () => {
        let check = true;
        setError({ ...error });
        if (check) {
            if (type === 'create') {
                await updatePolicyQuestionnaire(data, slug).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/questionair');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type === 'edit') {
                let id = data.id;
                delete data.id;
                await updatePolicyQuestionnaire(data, slug).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/questionair');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    // function removeItem(id) {
    //     Swal.fire({
    //         title: translate(props.lang, "deleteConfirmation"),
    //         text: translate(props.lang, "deleteMessage"),
    //         showDenyButton: true,
    //         showCancelButton: false,
    //         confirmButtonText: 'Yes',
    //         denyButtonText: 'No',
    //         customClass: {
    //             actions: 'my-actions',
    //             cancelButton: 'order-1 right-gap',
    //             confirmButton: 'order-2',
    //             denyButton: 'order-3',
    //         }
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             let res = await removeCompanyPolicy(id)
    //             if (res.status === 'success') {
    //                 promptMessage(lang, 'success', res.message)
    //                 getCompanyPolicies();
    //             } else {
    //                 promptMessage(lang, 'error', res.message)
    //                 getCompanyPolicies();
    //             }
    //         }
    //     })
    // }

    return (
        <>
            <div className="d-flex flex-column">
                <div className="col-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, "questionnaireForm")}
                                {/* {translate(lang, (type === "view" ? "detailsCompany" : type === "edit" ? "editCompany" : "createCompany"))} */}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column flex-md-row flex-wrap">
                            <div className="col-12">
                                <div className="card col-12 col-md-10 shadow">
                                    <div className="card-header d-flex flex-wrap">
                                        <h3 className="my-auto">
                                            <b>{policyCategory.name} - {translate(lang, "list")}</b>
                                        </h3>
                                        <span className="float-end">
                                            <button className="btn btn-sm btn-primary" onClick={() => addNewQuestion()}>{translate(lang, "addNewQuestion")}</button>
                                            {/* <span role="button" className="alert-link">EN</span>/
                                        <span role="button" className="alert-link">CN</span>/
                                        <span role="button" className="alert-link">BM</span> */}
                                        </span>
                                    </div>
                                    <div className="card-body questionnaire-list h-50-vh">
                                        {data.length > 0 ? (<>
                                            {data.map((dt, i) => (<>
                                                <div className="card h-auto shadow">
                                                    <div key={`head-${i}`} className="p-4 d-flex flex-wrap">
                                                        <div className="d-flex col-12">
                                                            <h3 className="my-auto px-3 cursor-pointer w-100" role="button" id={`nominationCollapse-${i}`}>{translate(lang, 'question')} {i + 1}</h3>
                                                            <div className="d-flex flex-row ms-auto">
                                                                {i === 0 ? (<></>) : (<>
                                                                    <span className="mx-3 my-auto font-25-bigger move-up-button-icon" onClick={() => moveUpQuestion(i)}><i class="fas fa-chevron-up"></i></span>
                                                                </>)}
                                                                {i === data.length - 1 ? (<></>) : (<>
                                                                    <span className="mx-3 my-auto font-25-bigger move-down-button-icon" onClick={() => moveDownQuestion(i)}><i class="fas fa-chevron-down"></i></span>
                                                                </>)}
                                                            <button className="btn btn-sm btn-danger" type="button" onClick={() => removeQuestion(i)}><i className="fas fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                        {/* <div className="d-flex flex-row col-12 col-md-2 justify-content-start justify-content-md-end">
                                                            <button className="btn btn-sm btn-danger" type="button" onClick={() => removeQuestion(i)}><i className="fas fa-times"></i></button> */}
                                                            {/* <button className="btn btn-sm btn-danger" type="button" onClick={() => removeQuestion(i)}>{translate(lang, 'delete')}</button> */}
                                                        {/* </div> */}
                                                    </div>
                                                    <UncontrolledCollapse key={`body-${i}`} className="questionnaire-list-container" toggler={`#nominationCollapse-${i}`}>
                                                        <div className="d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-column col-12 p-3">
                                                                <div className="d-flex flex-column flex-md-row flex-wrap px-2 py-1">
                                                                    {/* <div className="d-flex flex-row ms-auto">
                                                                        <button className="btn btn-sm btn-warning" type="button" onClick={() => removeQuestion(i)}>{translate(lang, 'delete')}</button>
                                                                    </div> */}
                                                                    <div className="col-12 mb-2">
                                                                        <label>{translate(lang, 'questionTitle')}</label>
                                                                        <input type="text" value={dt.questionTitle} name="questionTitle" onChange={(e) => onInputChange(e, i)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                    </div>
                                                                    <div className="col-12 mb-2">
                                                                        <label>{translate(lang, 'questionDescription')}</label>
                                                                        <textarea value={dt.questionDescription} name="questionDescription" onChange={(e) => onInputChange(e, i)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')}>
                                                                        </textarea>
                                                                        {/* <input type="text" value={dt.questionDescription} name="questionDescription" onChange={(e) => onInputChange(e, i)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                                                    </div>
                                                                    <div className="col-12 mb-2">
                                                                        <label className="form-label">{translate(lang, 'questionType')}</label>
                                                                        {/* <input type="text" value={data.questionType} name="questionType" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                                                        <Select
                                                                            className="form-control"
                                                                            value={questionairTypeOptions.filter(
                                                                                (option) => option.value === dt.questionType
                                                                            )}
                                                                            isDisabled={type === 'view' ? true : false}
                                                                            onChange={(e) => handleOptionSelected(e, "questionType", i)}
                                                                            options={questionairTypeOptions}
                                                                            placeholder={translate(lang, "placeholderChoose")}
                                                                            styles={{
                                                                                control: (base, state) => ({
                                                                                    ...base,
                                                                                    height: "100%",
                                                                                    border: 0,
                                                                                    borderColor: "transparent",
                                                                                    boxShadow: "none",
                                                                                    "&:hover": {
                                                                                        borderColor: "transparent",
                                                                                        boxShadow: "none",
                                                                                    },
                                                                                    backgroundColor: "transparent",
                                                                                }),
                                                                                valueContainer: (base, state) => ({
                                                                                    ...base,
                                                                                    paddingLeft: 0,
                                                                                    paddingRight: 0,
                                                                                    borderColor: "transparent",
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 mb-2">
                                                                        {dt.questionType === 'fillIn' ? (<></>) : (<>
                                                                            <label className="form-label">{translate(lang, 'questionOptions')}</label>
                                                                            {dt.questionOptions && dt.questionOptions.length > 0 ? (<>
                                                                                {
                                                                                    dt.questionOptions.map((opt, index) => (<>
                                                                                        <div className="d-flex flex-row">
                                                                                            <input type="text" value={opt.answer} name="questionOptions" onChange={(e) => onInputChange(e, i, { no: index, name: "answerOption" })} className="form-control mb-1" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                                            <span onClick={() => removeAnswerOption(i, index)}><i className="fas fa-times my-auto p-4 cursor-pointer cancel-button-icon font-25-bigger"></i></span>
                                                                                        </div>

                                                                                    </>))
                                                                                }
                                                                            </>) : (<><p>{translate(props.lang, "noAnswerOptionFound")}</p></>)}
                                                                            <button className="btn btn-sm btn-primary" onClick={() => addNewAnswerOption(i)}>{translate(lang, "addNewAnswer")}</button>
                                                                        </>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </UncontrolledCollapse>
                                                </div>
                                            </>))
                                            }
                                        </>) : (<><p>{translate(lang, "noQuestionFound")}</p></>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-sm btn-primary" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                                <button type="button" className="btn btn-sm btn-dark mx-2" onClick={() => history.push('/company')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(QuestionairForm);